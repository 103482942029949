import { admin2 } from '@/api/instance'

// => 取得訂單
export const GetOrder = async ({
  shopId,
  start,
  limit,
  code,
  status,
  MemberId,
  EcommerceDeliveryId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceOrder`,
    params: {
      start,
      limit,
      code,
      status,
      MemberId,
      EcommerceDeliveryId,
    },
  })
  return res
}

export const ExportOrder = async ({
  shopId,
  start,
  limit,
  status,
  createdAtStart,
  createdAtEnd,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/ecommerceOrder/detail`,
    params: {
      start,
      limit,
      status,
      createdAtStart,
      createdAtEnd,
    },
  })
}

// => 取得訂單數量
export const GetOrderCount = async ({
  shopId,
  code,
  status,
  MemberId,
  EcommerceDeliveryId,
  createdAtStart,
  createdAtEnd,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceOrder/count`,
    params: {
      code,
      status,
      MemberId,
      EcommerceDeliveryId,
      createdAtStart,
      createdAtEnd,
    },
  })
  return res
}

// => 取得特定配送方式
export const FindOrder = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceOrder/${id}`,
  })
  return res
}

// => 更新付款狀態
export const UpdatePaymentStatus = async ({ shopId, id, status }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/payment`,
    data: {
      status,
    },
  })
  return res
}

// => 更新付款狀態
export const AutoRefunded = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceOrder/${id}/autoRefunded`,
  })
  return res
}

// => 更新付款狀態
export const WalletRecordRefunded = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceOrder/${id}/walletRecordRefunded`,
  })
  return res
}

// => 更新配送狀態
export const UpdateDeliveryStatus = async ({ shopId, id, status }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/delivery/status`,
    data: {
      status,
    },
  })
  return res
}

// => 更新配送狀態
export const UpdateDeliveryInfo = async ({ shopId, id, code }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/delivery/info`,
    data: {
      code,
    },
  })
  return res
}

// => 取消訂單
export const CancelOrder = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceOrder/${id}/cancel`,
  })
  return res
}

// => 下載訂單揀貨單
export const GenerateOrderPickupSheet = async ({ shopId, id }) => {
  const res = await admin2({
    url: `/${shopId}/ecommerceOrder/generateDeliveryPrintLink`,
    params: {
      ecommerceOrderIds: id,
    },
  })
  return res
}

// => 更新發票狀態
export const UpdateInvoiceStatus = async ({ shopId, id, status }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/invoice/status`,
    data: {
      status,
    },
  })
  return res
}

// => 更新退款狀態
export const UpdateRefundedStatus = async ({ shopId, id, status }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/refunded/status`,
    data: {
      status,
    },
  })
  return res
}

// => 更新退款狀態
export const UpdateInvoiceNo = async ({ shopId, id, invoiceNo }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/invoice/invoiceNo`,
    data: {
      invoiceNo,
    },
  })
  return res
}

// => 更新店家備註
export const UpdateShopNote = async ({ shopId, id, text }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/ecommerceOrderNoteShop`,
    data: {
      text,
    },
  })
  return res
}

// => 更新管理員備註
export const UpdateAdminNote = async ({ shopId, id, text }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceOrder/${id}/ecommerceOrderNoteAdmin`,
    data: {
      text,
    },
  })
  return res
}

export const UpdatePprocessAddCashback = async ({
  shopId,
  id,
  action,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/ecommerceOrder/${id}/processAddCashback`,
    data: {
      action,
    },
  })
}
