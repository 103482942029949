import ls from 'local-storage'
import { map } from 'lodash'

export const showColumn = (column, config) => {
  return config.includes(column)
}

export const updateTableFilterConfig = (table, checked) => {
  const tableFilterConfig = ls.get('tableFilterConfig') ?? {}
  // if (!tableFilterConfig[table]) tableFilterConfig[table] = []
  tableFilterConfig[table] = checked
  ls.set('tableFilterConfig', tableFilterConfig)
}

export const getTableFilterConfig = (tableName, optionsConfig) => {
  const tableFilterConfig = ls.get('tableFilterConfig') ?? {}
  if (!tableFilterConfig[tableName]) {
    tableFilterConfig[tableName] = map(optionsConfig, 'value')
    ls.set('tableFilterConfig', tableFilterConfig)
  }
  return tableFilterConfig[tableName]
}
