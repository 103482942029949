import { render, staticRenderFns } from "./MemberLevelDetail.vue?vue&type=template&id=48aff1c7&scoped=true"
import script from "./MemberLevelDetail.vue?vue&type=script&lang=js"
export * from "./MemberLevelDetail.vue?vue&type=script&lang=js"
import style0 from "./MemberLevelDetail.vue?vue&type=style&index=0&id=48aff1c7&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48aff1c7",
  null
  
)

export default component.exports