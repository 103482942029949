import { orderOrigins } from '@/config/reservation'

export const reservationOrderOrigins = ({ notSpecify, origin, originText }) => {
  if (!origin) return '-'
  let label = '-'
  const res = orderOrigins[origin]
  if (!res) return label
  label = res.label
  // if (origin === 'client') return notSpecify ? `${label} (不指定)` : `${label} (指定)`
  if (origin === 'openAPI') {
    if (originText) return `${label} (${originText})`
  }
  return label
}
