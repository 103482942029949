<template>
  <el-drawer class="reservation-order-history" size="600px" title="訂單紀錄" :visible="syncShow" @close="onClose">
    <HistoryRecord :historyData="displayData">
      <template v-slot="{record}">
        <div class="record-content">{{ record.content }}</div>
      </template>
    </HistoryRecord>
  </el-drawer>
</template>

<script>
import { defineComponent, computed, watch, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { useVModel } from '@vueuse/core'
import { FindReservationOrderHistory } from '@/api/reservation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import HistoryRecord from '@/components/HistoryRecord/HistoryRecord.vue'
import { orderHistoryEventTypeConfig } from '@/config/reservation'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'ReservationOrderHistory',
  components: { HistoryRecord },
  props: {
    show: Boolean,
    orderId: String,
  },
  emit: ['update:show', 'close'],
  setup (props, { emit }) {
    const syncShow = useVModel(props, 'show', emit)
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const loading = ref(false)
    const historyData = ref([])

    const onClose = () => {
      syncShow.value = false
      setTimeout(() => {
        emit('close')
      }, 0)
    }

    const displayData = computed(() => {
      return map(historyData.value, (record) => {
        const type = get(orderHistoryEventTypeConfig, record.type)
        console.log(type)
        const content = type ? type.label : ''

        return {
          updatedAt: record.updatedAt,
          id: record.id,
          content,
        }
      })
    })

    watch(syncShow, async (show) => {
      if (show) {
        loading.value = true
        await fetchAll(FindReservationOrderHistory, { shopId: shopId.value, orderId: props.orderId },
          (res) => {
            historyData.value = res
          })
        loading.value = false
      }
    })

    return { syncShow, onClose, historyData, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.record-content {
  @apply font-bold text-sub text-gray-100;
}
</style>
