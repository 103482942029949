export const reservationListFilterOptions = [
  { label: '訂單類型', value: 'orderType' },
  { label: '個案姓名', value: 'subsidyName' },
  { label: '長照等級', value: 'subsidyLevel' },
  { label: '陪病者', value: 'subsidyPartner' },
  { label: '預約人', value: 'userName' },
  { label: '電話號碼', value: 'phone' },
  { label: '個案地點', value: 'userAddress' },
  { label: '預約日期', value: 'date' },
  { label: '預約時間', value: 'time' },
  { label: '指定地點', value: 'service' },
  { label: '班次', value: 'serviceUnit' },
  { label: '服務設備', value: 'resourceItem' },
  { label: '預約來源', value: 'origin' },
  { label: '訂單總額', value: 'orderPrice' },
  { label: '應付訂金', value: 'depositPrice' },
  { label: '付款方式', value: 'paymentType' },
  { label: '建立時間', value: 'createdAt' },
]

export const memberReservationListFilterOptions = [
  { label: '訂單類型', value: 'orderType' },
  { label: '個案地點', value: 'userAddress' },
  { label: '預約日期', value: 'date' },
  // { label: '預約時間', value: 'time' },
  { label: '服務項目', value: 'service' },
  { label: '服務人員', value: 'serviceUnit' },
  { label: '預約來源', value: 'origin' },
  { label: '訂單狀態', value: 'orderStatus' },
  { label: '訂單總額', value: 'orderPrice' },
  { label: '應付訂金', value: 'depositPrice' },
  { label: '付款方式', value: 'paymentType' },
  { label: '付款狀態', value: 'paymentStatus' },
]

export const memberManageFilterOptions = [
  { label: '電話號碼', value: 'phone' },
  { label: '生日', value: 'birthday' },
  { label: '等級', value: 'level' },
  { label: '標籤', value: 'tag' },
  { label: '聯絡地址', value: 'address' },
  { label: '登入方式', value: 'loginType' },
  { label: '黑名單', value: 'blackList' },
]

export const subsidyMemberManageFilterOptions = [
  { label: '營運縣市', value: 'city' },
  { label: '營運單位', value: 'operatingUnit' },
  { label: '等級', value: 'level' },
  { label: '個案驗證碼', value: 'memberId' },
  { label: '補助狀態', value: 'status' },
  { label: '建立時間', value: 'createdAt' },
]
