<template>
  <div class="member-invite-record-item">
    <p class="dateTime">{{ displayData.dateTime }}</p>
    <div v-if="displayData.user" class="row">
      <p class="label">操作人員</p>
      <p>{{ displayData.user }}</p>
    </div>

    <div class="row">
      <p class="label">會員名稱</p>
      <p class="value">{{ displayData.username }}</p>
    </div>
    <div class="row">
      <p class="label">手機號碼</p>
      <p class="value">{{ displayData.phone }}</p>
    </div>
    <div class="row">
      <p class="label">Line 綁定狀態</p>
      <p class="value">{{ displayData.status }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'MemberInviteRecordItem',
  props: {
    record: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = props.record

      const successAt = get(data, 'successAt')

      return {
        id: data.id,
        dateTime: formatDate(get(data, 'updatedAt'), 'YYYY/MM/DD HH:mm'),
        username: get(data, 'Referee.UserInfo.name'),
        phone: get(data, 'Referee.UserInfo.phone'),
        status: successAt ? '已綁定' : '未綁定',
      }
    })

    const sourceConfig = {
      manual: { label: '手動更改', value: 'manual' },
      init: { label: '初始等級', value: 'init' },
      systemUpgrade: { label: '系統升等', value: 'systemUpgrade' },
      systemDowngrade: { label: '系統降等', value: 'systemDowngrade' },
      systemRemain: { label: '系統續等', value: 'systemRemain' },
      configChange: { label: '設定異動', value: 'configChange' },
    }

    return { sourceConfig, get, formatDate, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.member-invite-record-item {
  @apply mb-[24px]
}

.row {
    @apply flex gap-[12px] ;
}

.dateTime {
    @apply font-bold text-sub text-gray-60;
}

.row .label {
    @apply font-bold text-base;
    font-size: 14px;
}

.value {
    @apply text-sub text-gray-60;
}
</style>
