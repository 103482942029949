<template>
  <div class="input-file">
    <el-upload
      ref="upload"
      action=""
      :auto-upload="autoUpload"
      :accept="accept"
      :drag="dragable"
      :disabled="hasFile"
      :show-file-list="false"
      :on-change="onFile"
      :file-list="fileList"
      multiple
      :limit="1"
    >
      <slot v-if="!hasFile" name="icon">
        <i class="el-icon-upload" />
      </slot>
      <slot v-if="!hasFile" name="text">
        <div class="el-upload__text">拖拽上傳或是<em>選取檔案</em></div>
        <div slot="tip" class="el-upload__tip">
          <slot name="tip" :size="size" />
        </div>
      </slot>
    </el-upload>
    <div v-if="hasFile" class="temp-upload">
      <div class="text-center">
        <div class="w-[68px] mx-auto text-primary-100">
          <MaterialIcon pointer>check_circle</MaterialIcon>
        </div>
        <p>檔案選取成功</p>
      </div>
      <div class="flex items-center justify-center">
        <span class="text-primary-100 underline">{{ fileList[0].name }}</span>
        <div class="flex items-center justify-center" @click="onRemove">
          <MaterialIcon pointer>delete</MaterialIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, nextTick } from 'vue'
export default {
  name: 'InputFile',
  props: {
    modelValue: { type: Object, required: true },
    autoUpload: { type: Boolean, default: false },
    drag: { type: Boolean, default: true },
    accept: { type: String, required: true },
    size: { type: Number, default: 10 },
  },
  setup (props, { emit }) {
    const observeFile = computed({
      get: () => {
        return props.modelValue
      },
      set: (value) => {
        emit('update:modelValue', value)
      },
    })

    let _tempRaw = null
    const reader = new FileReader()

    const onLoad = (event) => {
      const file = new Blob([event.target.result], { type: _tempRaw.type })
      const blobURL = window.URL.createObjectURL(file)
      observeFile.value = {
        raw: _tempRaw,
        url: blobURL,
      }
    }

    reader.onload = onLoad
    const upload = ref()

    const onFile = (file) => {
      const fileType = file.raw.type

      if (!props.accept.includes(fileType)) {
        emit('errorFileType')
        upload.value.clearFiles()
        return
      }

      const fileSize = file.size / 1024 / 1024
      if (props.size < fileSize) {
        emit('errorFileSize')
        upload.value.clearFiles()
        return
      }
      _tempRaw = file.raw

      reader.readAsArrayBuffer(file.raw)
    }
    const fileList = computed(() => {
      const { blobURL, url, raw, id, filename } = observeFile.value || {}
      if (blobURL || url) {
        return [{ url: blobURL || url, name: raw?.name, status: '' }]
      }
      if (id && filename) {
        return [{ name: filename }]
      }
      return []
    })

    const onRemove = async () => {
      await nextTick()
      observeFile.value = {}
    }

    const hasFile = computed(() => fileList.value.length > 0)

    const dragable = computed(() => {
      return props.drag && !hasFile.value
    })

    return {
      onFile,
      observeFile,
      fileList,
      onRemove,
      upload,
      dragable,
      hasFile,
    }
  },
}
</script>

<style lang="scss">
.input-file {
  .el-upload {
    @apply w-full;
  }
  .el-upload-dragger {
    @apply w-full;
  }
  .temp-upload {
    @apply py-6;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23204BB4' stroke-width='4' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}
</style>
