export const originConfig = {
  ADMIN_ADD: { name: '後台贈送', value: 'ADMIN_ADD' },
  ADMIN_USE: { name: '後台扣除', value: 'ADMIN_USE' },
  EXPIRED: { name: '過期', value: 'EXPIRED' },
  MEMBER_STORE_USE: { name: '點數兌換', value: 'MEMBER_STORE_USE' },
  REWARD_ACTIVITY_SALE_RECORD_ADD: { name: '銷售回饋', value: 'REWARD_ACTIVITY_SALE_RECORD_ADD' },
  REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL: { name: '銷售回收', value: 'REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL' },
  REWARD_ACTIVITY_MEMBER_STORE_ADD: { name: '商城回饋', value: 'REWARD_ACTIVITY_MEMBER_STORE_ADD' },
  REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL: { name: '商城回收', value: 'REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL' },
  REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD: { name: '消費回饋', value: 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD' },
  REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL: { name: '消費回收', value: 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL' },
  LOTTERY_AWARD_ADD: { name: '抽獎活動獲得', value: 'LOTTERY_AWARD_ADD' },
  REWARD_ACTIVITY_EC_ORDER_ADD: { name: '電商回饋', value: 'REWARD_ACTIVITY_EC_ORDER_ADD' },
  REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL: { name: '電商回收', value: 'REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL' },
  SERIAL_ACTIVITY_ADD: { name: '序號登錄獲得', value: 'SERIAL_ACTIVITY_ADD' },
  EVENT_PLAYBOOK_SEND: { name: '系統行銷活動發放', value: 'EVENT_PLAYBOOK_SEND' },
  EXTEND_IN: { name: '點數展延-當年度轉入', value: 'EXTEND_IN' },
  EXTEND_OUT: { name: '點數展延-當年度轉出', value: 'EXTEND_OUT' },
}
