
<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增堂票"
      btn2="匯出"
      @btnClick="classTicketDialog = true"
      @btn2Click="dialog.export = true"
    />
    <FiltersContainer>
      <el-input
        v-model="search.code"
        clearable
        placeholder="搜尋堂票編號"
        @keypress.enter.native="refresh"
        @clear="refresh"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh"
        />
      </el-input>

      <!-- <el-button
        type="primary"
        @click="classTicketDialog = true"
      >
        新增堂票
      </el-button> -->
    </FiltersContainer>

    <el-table
      v-loading="loading"
      :data="classTicketRecordList"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <el-table-column
        prop="code"
        label="堂票編號"
        width="115"
        align="center"
        fixed="left"
      />
      <el-table-column
        prop="name"
        label="名稱"
        width="115"
        align="center"
        fixed="left"
      />
      <el-table-column prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="classTicketTagType(scope.row.status)">
            {{ statusLabel(scope.row) }}
          </Tag>
        </template>
      </el-table-column>
      <el-table-column label="可用張數" align="center">
        <template slot-scope="scope">
          {{ scope.row.availableTimes }}
        </template>
      </el-table-column>
      <el-table-column label="已用張數" align="center">
        <template slot-scope="scope">
          {{ scope.row.usedTimes }}
        </template>
      </el-table-column>
      <el-table-column v-if="useTicketPermission" label="已贈張數" align="center">
        <template slot-scope="scope">
          {{ scope.row.giveTimes }}
        </template>
      </el-table-column>
      <el-table-column
        prop="origin"
        label="新增來源"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ mapOrigin(scope.row.origin) }}
        </template>
      </el-table-column>
      <el-table-column v-if="false" prop="Branch" label="操作門市" align="center">
        <template slot-scope="scope">
          {{ scope.row.Branch?.name || "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="價格" align="center" />
      <el-table-column prop="isExp" label="有效期限" align="center">
        <template slot-scope="scope">
          <el-tag
            disable-transitions
            :type="classTicketIsExpTag(scope.row.isExp)"
          >
            {{ scope.row.isExp ? '有' : '無' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="exp" label="有效日期" width="120" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.exp) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="備註"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.note || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="建立時間"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="120">
        <template slot-scope="scope">
          <TableEditBtnGroup
            deleteBtn="作廢"
            :editDisabled="scope.row.status !== 'open'"
            :deleteDisabled="scope.row.status !== 'open'"
            @edit="findClassTicketRecord(scope.row)"
            @delete=";(deleteDialog = true), (selectRow = scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      :curPage.sync="classTicketRecordTableOptions.page"
      :pageLimit="classTicketRecordTableOptions.pageLimit"
      :total="classTicketRecordCount"
      @pageChange="refresh('classTicket')"
    />

    <!-- 新增堂票 -->
    <el-dialog
      title="新增堂票"
      :visible.sync="classTicketDialog"
      :close-on-click-modal="false"
      @close="resetClassTicketForm"
    >
      <el-form
        ref="classTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <el-form-item label="堂票" prop="classTicket">
          <ClassTicketSearch
            :model.sync="classTicketForm.classTicket"
            objKey="id"
          />
        </el-form-item>
        <el-form-item label="組數" prop="quantity">
          <el-input
            v-model="classTicketForm.quantity"
            placeholder="請輸入數量"
          />
        </el-form-item>
        <el-form-item label="單價" prop="price">
          <el-input
            v-model="classTicketForm.price"
            type="number"
            placeholder="請輸入單價"
          />
        </el-form-item>
        <el-form-item label="備註" prop="note">
          <el-input v-model="classTicketForm.note" placeholder="請輸入" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button plain @click="classTicketDialog = false">返回</el-button>
        <el-button type="primary" @click="deBounce">確認</el-button>
      </div>
    </el-dialog>

    <!-- 堂票使用紀錄 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="classTicketRecordDialog"
      @close="resetForm('useTicketForm')"
    >
      <template slot="title">
        <div style="padding-right: 30px">
          {{ `使用堂票紀錄 - ${classTicketForm.name}` }}
        </div>
      </template>
      <el-form
        ref="classTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <el-form-item
          label="使用堂票"
          :rules="[isDigit(), noEmpty(), useRange()]"
          prop="use"
        >
          <div class="flex">
            <el-input
              v-model="classTicketForm.use"
              style="width: 150px; margin-right: 20px"
              placeholder="請輸入次數"
            />
            <el-button
              ref="useTicketBtn"
              type="primary"
              @click="useClassTicket"
            >
              確認
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <el-form
        ref="cancelTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <el-form-item
          label="取消使用"
          :rules="[isDigit(), noEmpty(), cancelRange()]"
          prop="cancel"
        >
          <div class="flex">
            <el-input
              v-model="classTicketForm.cancel"
              style="width: 150px; margin-right: 20px"
              placeholder="請輸入次數"
            />
            <el-button
              ref="cancelTicketBtn"
              type="primary"
              @click="cancelUseClassTicket"
            >
              確認
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <hr>

      <div class="use-history-container">
        <div v-if="classTicketUseList.length === 0">暫無使用紀錄</div>
        <div style="margin-top: 10.8px">剩餘使用次數: {{ availableTimes }}</div>
        <div
          v-for="item in classTicketUseList"
          v-show="classTicketUseList.length"
          :key="`cancel-${item.id}`"
          class="flex-between"
        >
          <template v-if="!item.isCancelType">
            <div>使用次數: {{ item.times }}</div>
            <div v-if="false">XX門市</div>
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
          <template v-if="item.isCancelType">
            <div>取消使用次數: {{ Math.abs(item.times) }}</div>
            <div v-if="false">XX門市</div>
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
        </div>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="cancelClassTicketRecord"
    />

    <ExportOptionsDialog
      v-if="dialog.export"
      allRange
      @close="dialog.export = false"
      @export="dialog.export = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import ClassTicketSearch from '@/components/Search/ClassTicketSearch.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import {
  CreateClassTicketRecord,
  GetClassTicketRecord,
  GetClassTicketRecordCount,
  GetClassTicketUseRecord,
  GetClassTicketUseRecordCount,
  FindClassTicketRecord,
  CancelClassTicketRecord,
  CancelUseClassTicket,
  GetClassTicketRecordSerial,
  UseClassTicket,
} from '@/api/classTicket'
import debounce from 'lodash.debounce'
import formUtils from '@/utils/form'
import { pageStartIndex } from '@/utils/table'
import { noEmptyRules, isDigitRules, rangeRules, defaultRangeMax } from '@/validation/index'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import MixinFunc from '@/components/MixinFunc.vue'
import ExportMixin from '@/mixin/export'
import dayjs from 'dayjs'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { getAllDataFromApi } from '@/utils/helper'
import { get, map, join } from 'lodash'
import {
  classTicketUseRecordOriginConfig,
  classTicketUseRecordTypeConfig,
} from '@/config/classTicket'
import { ExportMoreSheetExcel } from '@/utils/excel'

export default {
  name: 'ClassTicketTab',
  components: {
    ClassTicketSearch,
    DeleteDialog,
    EmptyBlock,
    ExportOptionsDialog,
    ExportDialog,
  },
  mixins: [MixinFunc, ExportMixin],
  props: ['member'],
  setup () {
    return { ExportMoreSheetExcel }
  },
  data () {
    return {
      defaultRangeMax,
      loading: false,
      classTicketRecordCount: 0,
      classTicketRecordList: [],
      classTicketUseList: [],
      classTicketRecordTableOptions: {
        page: 1,
        pageLimit: 10,
      },

      selectRow: null,
      classTicketForm: {
        classTicket: [],
        price: '',
        note: '',
        times: 1,
        cancel: '',
        name: '',
        quantity: 1,
      },

      classTicketFormRules: {
        classTicket: [noEmptyRules()],
        price: [isDigitRules(), noEmptyRules(), rangeRules()],
        quantity: [noEmptyRules(), rangeRules(1)],
        times: [
          isDigitRules(),
          noEmptyRules(),
          rangeRules(1, this.availableTimes),
        ],
        cancel: [isDigitRules(), noEmptyRules(), rangeRules(1, this.usedTimes)],
      },

      classTicketDialog: false,
      classTicketRecordDialog: false,
      deleteDialog: false,

      search: {
        code: '',
      },

      dialog: {
        export: false,
      },
      warning: {
        type: null,
        error: null,
        total: 0,
        success: 0,
        fail: 0,
      },
      processing: {
        task: null,
        detail: null,
        title: '',
        inProgress: false,
        isError: false,
        percentage: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    shop () {
      return this.$store.getters.shop
    },
    mapOrigin () {
      const origins = {
        admin: '會員後台',
        sales: '銷售紀錄',
        give: '會員轉贈',
        gift: '禮物贈送',
        memberStore: '會員商城',
        eventPlaybook: '系統行銷活動發放',
      }
      return (key) => origins[key] || '-'
    },
    classTicketRecordStartIndex () {
      return pageStartIndex(
        this.classTicketRecordTableOptions.page,
        this.classTicketRecordTableOptions.pageLimit,
      )
    },

    useClassTicketFeature () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicket.page')
    },

    useStorePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.branch.adminUseStore')
    },

    useTicketPermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicketConfig.clientGive')
    },

    availableTimes () {
      if (!this.selectRow) return 0
      const available = this.selectRow.availableTimes
      const used = this.selectRow.usedTimes
      return available - used
    },
    usedTimes () {
      if (!this.selectRow) return 0
      const used = this.selectRow.usedTimes
      return used
    },
  },
  async mounted () {
    await this.refresh()
  },
  methods: {
    isDigit () {
      return isDigitRules()
    },
    noEmpty () {
      return noEmptyRules()
    },
    useRange () {
      return rangeRules(1, this.availableTimes)
    },
    cancelRange () {
      return rangeRules(1, this.usedTimes)
    },
    async refresh () {
      this.loading = true
      await this.getClassTicketRecord()
      await this.getClassTicketRecordCount()
      this.loading = false
    },
    resetClassTicketForm () {
      formUtils.resetForm(this.$refs.classTicketForm)
      this.classTicketForm = {
        classTicket: [],
        price: '',
        note: '',
        times: 1,
        name: '',
      }
    },

    statusLabel (data) {
      if (data.usedTimes || data.availableTimes) {
        if (data.usedTimes === data.availableTimes) return '使用完畢'
      }
      if (data.status === 'overdue') return '已過期'
      else if (data.status === 'open') return '可使用'
      else if (data.status === 'complete') return '使用完畢'
      else if (data.status === 'cancel') return '已作廢'
    },

    resetForm (form) {
      formUtils.resetForm(this.$refs[form])
    },

    classTicketTagType (val) {
      let type = 'info'
      if (val === 'overdue') type = 'warning'
      if (val === 'open') type = 'action'
      if (val === 'cancel') type = 'danger'
      return type
    },

    classTicketIsExpTag (val) {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'warning'
      return type
    },

    //= > 取得堂票紀錄
    async getClassTicketRecord () {
      try {
        const res = await GetClassTicketRecord({
          shopId: this.shop,
          start: this.classTicketRecordStartIndex,
          limit: this.classTicketRecordTableOptions.pageLimit,
          code: this.search.code === '' ? undefined : this.search.code,
          MemberId: this.member.id,
        })

        this.classTicketRecordList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得堂票紀錄總數
    async getClassTicketRecordCount () {
      try {
        this.classTicketRecordCount = await GetClassTicketRecordCount({
          shopId: this.shop,
          code: this.search.code === '' ? undefined : this.search.code,
          MemberId: this.member.id,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 使用堂票
    async useClassTicket () {
      if (this.availableTimes === 0) { return this.$message.error('目前已無剩餘次數') }
      if (!(await formUtils.checkForm(this.$refs.classTicketForm))) return
      this.$refs.useTicketBtn.loading = true
      try {
        await UseClassTicket({
          shopId: this.shop,
          id: this.selectRow.id,
          times: this.classTicketForm.use,
        })
        await this.getClassTicketRecord()

        const res = await FindClassTicketRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.classTicketUseList = res.ClassTicketRecordUses
        this.sortUseRecordList()
        this.updateSelectedClassTicketUsedTimes()

        this.$message.success('已使用堂票!')
        this.$refs.useTicketBtn.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error('目前已無剩餘次數')
        this.$refs.useTicketBtn.loading = false
      }
    },

    //= > 使用堂票
    async cancelUseClassTicket () {
      if (!(await formUtils.checkForm(this.$refs.cancelTicketForm))) return
      this.$refs.cancelTicketBtn.loading = true
      try {
        await CancelUseClassTicket({
          shopId: this.shop,
          id: this.selectRow.id,
          times: this.classTicketForm.cancel,
        })
        await this.getClassTicketRecord()

        const res = await FindClassTicketRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.classTicketUseList = res.ClassTicketRecordUses
        this.sortUseRecordList()
        this.updateSelectedClassTicketUsedTimes()

        this.$message.success('已取消使用堂票!')
        this.$refs.cancelTicketBtn.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
        this.$refs.cancelTicketBtn.loading = false
      }
    },

    sortUseRecordList () {
      this.classTicketUseList.sort((a, b) => {
        if (dayjs(a.createdAt).unix() > dayjs(b.createdAt).unix()) return -1
        else if (dayjs(b.createdAt).unix() > dayjs(a.createdAt).unix()) return 1
      })
    },

    deBounce: debounce(
      async function () {
        try {
          if (!(await formUtils.checkForm(this.$refs.classTicketForm))) { return }
          await CreateClassTicketRecord({
            shopId: this.shop,
            memberId: this.member.id,
            classTicketId: this.classTicketForm.classTicket,
            price: this.classTicketForm.price,
            quantity: this.classTicketForm.quantity,
            note:
              this.classTicketForm.note === ''
                ? undefined
                : this.classTicketForm.note,
          })
          this.$message.success('新增堂票成功')
          await this.getClassTicketRecord()
          this.classTicketDialog = false
          this.resetClassTicketForm()
        } catch (error) {
          console.log(error)
          this.$message.error({
            message: error || error.message,
          })
        }
        this.classTicketDialog = false
      },
      300,
      { leading: true, trailing: false },
    ),

    //= > 查找堂票紀錄
    async findClassTicketRecord (row) {
      try {
        this.selectRow = row
        // if(column.label === '操作') return
        this.classTicketForm.name = row.name
        this.classTicketRecordDialog = true
        const res = await FindClassTicketRecord({
          shopId: this.shop,
          id: row.id,
        })
        this.classTicketUseList = res.ClassTicketRecordUses
        this.sortUseRecordList()
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 作廢堂票紀錄
    async cancelClassTicketRecord () {
      try {
        await CancelClassTicketRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.getClassTicketRecord()
        this.deleteDialog = false
        this.$message.success('取消成功')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    // -- 更新選擇中堂票資訊
    updateSelectedClassTicketUsedTimes () {
      const target = this.classTicketRecordList.find(
        (item) => item.id === this.selectRow.id,
      )
      const usedTimes = target.usedTimes || 0
      const availableTimes = target.availableTimes || 0

      if (usedTimes > 0) this.selectRow.usedTimes = usedTimes
      if (availableTimes > 0) this.selectRow.availableTimes = availableTimes
    },

    // ----------- Export -----------
    async getExportData (type) {
      let fetchApi = GetClassTicketRecord
      if (type === 'use') fetchApi = GetClassTicketUseRecord
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        search: this.search.all || undefined,
        MemberId: this.member.id || undefined,
      }

      return await getAllDataFromApi(
        this.exportTotal,
        fetchApi,
        payload,
      )
    },
    resetProcessing () {
      this.processing.task = null
      this.processing.title = ''
      this.processing.percentage = 0
      this.processing.isError = false
      this.processing.inProgress = false
    },
    async prepareExport () {
      this.resetExport()
      // 取得匯出數據數量

      const res = await GetClassTicketRecordCount({
        shopId: this.shop,
        search: this.search.all || undefined,
        MemberId: this.member.id || undefined,
      })
      this.exportTotal = res
      if (!this.exportTotal) {
        this.$message.warning('沒有資料可以匯出')
        return
      }
      this.showExportDialog = true
      this.exportting = true
      try {
        const addData = await this.getExportData('add')
        this.exportData = addData
        const useData = await this.getExportData('use')
        this.exportUseData = useData
        await this.formatExportData()
        this.exportting = false
      } catch (error) {
        console.log(error)
        this.exportting = false
        this.exportError = true
        this.$message.error(error)
      }
    },
    async recordSerial () {
      const [res, err] = await GetClassTicketRecordSerial({
        shopId: this.shop,
        start: 0,
        limit: 100,
        MemberId: this.member.id || undefined,
      })
      if (err) this.$message.error(err)
      return res
    },
    async formatAddRecordData (item) {
      return {
        堂票編號: item.code,
        姓名: get(item, 'Member.UserInfo.name') || '非會員',
        堂票名稱: item.name,
        狀態: this.statusLabel(item),
        可用張數: item.availableTimes,
        已用張數: item.usedTimes,
        已贈張數: item.giveTimes,
        價格: item.price,
        新增來源: this.mapOrigin(item.origin),
        有效期限: item.isExp ? '有' : '無',
        有效日期: this.dateFormat(item.exp),
        備註: item.note,
        建立時間: this.dateFormat(item.createdAt),
      }
    },
    async formatAddRecordSerialData (item) {
      return {
        堂票序號編號: get(item, 'code'),
        堂票序號狀態: this.statusLabel(item),
        會員姓名: get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        堂票編號: get(item, 'ClassTicketRecord.code'),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票狀態: this.statusLabel(get(item, 'ClassTicketRecord')),
        有效期限: get(item, 'ClassTicketRecord.isExp') ? '有' : '無',
        有效日期: this.dateFormat(get(item, 'ClassTicketRecord.isExp')),
        新增來源: this.mapOrigin(get(item, 'ClassTicketRecord.origin')),
        是否履約: get(item, 'TicketPaymentPromise.ticketPromiseRecordId') ? '是' : '否',
        // 是否已送履約核銷: '-',
        建立時間: this.dateFormat(item.createdAt),
      }
    },
    formatUseRecordData (item) {
      return {
        使用時間: this.dateFormat(item.createdAt),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票編號: get(item, 'ClassTicketRecord.code'),
        使用來源: get(classTicketUseRecordOriginConfig, `${item.origin}.label`),
        使用門市: get(item, 'Branch.name'),
        會員名稱:
          get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        使用類型: get(classTicketUseRecordTypeConfig, `${item.type}.label`),
        '使用/取消': item.isCancelType ? '取消' : '使用',
        '使用/取消次數': Math.abs(item.times),
        堂票序號陣列: join(
          map(item.ClassTicketRecordSerials, 'code'),
          ',',
        ),
      }
    },
    async formatExportData () {
      const addData = []
      const serialRecordData = []
      const useData = []

      let count = 0
      const recordSerialData = await this.recordSerial()
      for (const subitem of recordSerialData) {
        const row = await this.formatAddRecordSerialData(subitem)
        serialRecordData.push(row)
      }
      for (const item of this.exportData) {
        addData.push(await this.formatAddRecordData(item))
        // serialRecordData.push(await this.formatAddRecordSerialData(item))
        count++
        this.exportPercentage = (count / this.exportTotal) * 100
      }
      for (const item of this.exportUseData) {
        useData.push(this.formatUseRecordData(item))
      }
      const sheets = [
        {
          sheetName: '堂票新增紀錄',
          data: addData,
        },
        {
          sheetName: '堂票序號新增紀錄',
          data: serialRecordData,
        },
        {
          sheetName: '堂票使用紀錄',
          data: useData,
        },
      ]
      this.ExportMoreSheetExcel(sheets, '堂票新增紀錄')
    },
  },
}
</script>

<style scoped lang="scss">
.use-history-container {
  @apply grid grid-flow-row gap-[8px];
}
</style>
