<template>
  <div class="order-info">
    <el-form
      v-if="order"
      label-width="150px"
      label-position="left"
    >
      <!-- <p class="section-title text-primary-100 font-medium">{{ index }}.詳細資訊</p> -->
      <el-form-item label="操作">
        <el-button :disabled="isCancel || editUserPermission" class="primary-btn underline" type="text" @click="showEditDialog = true">
          編輯詳細資訊
        </el-button>
      </el-form-item>
      <el-form-item label="預約單號">
        <div>{{ order.numberCode }}</div>
      </el-form-item>
      <el-form-item label="服務對象姓名">
        <div>{{ order.userName || '未填寫' }}</div>
      </el-form-item>
      <el-form-item label="服務對象電話">
        <div>{{ order.userPhone || '未填寫' }}</div>
      </el-form-item>
      <el-form-item label="預約時間">
        <div>{{ dateFormat(order.start) }}</div>
      </el-form-item>
      <el-form-item label="服務項目">
        <div>{{ getData(order, 'AppointmentService.name', '') }}</div>
      </el-form-item>
      <el-form-item v-if="getData(order, 'AppointmentSubService.name')" label="子項目">
        <div>{{ getData(order, 'AppointmentSubService.name', '') }}</div>
      </el-form-item>
      <el-form-item label="服務車次">
        <div>{{ showNotSpecify(order) }}</div>
      </el-form-item>
      <el-form-item label="接送地址">
        <div class="flex items-center">
          <div style="max-width: 320px; line-height: normal">{{ getData(order, 'AppointmentService.address', '') }}</div>
          <span class="goto-address" @click="gotoMap">地圖</span>
          <img alt="" src="@/assets/icon/tabler-location.svg" class="cursor-pointer" @click="gotoMap">
        </div>
      </el-form-item>
      <el-form-item v-if="useServiceResource" label="服務設備">
        <div>{{ getData(order, 'ResourceItem.name', '-') }}</div>
      </el-form-item>
      <el-form-item label="附加服務">
        <div>{{ attachServices }}</div>
      </el-form-item>
      <el-form-item label="建立時間">
        <div>{{ createdAt }}</div>
      </el-form-item>
      <el-form-item label="上車時間">
        <div>{{ dateFormat(getData(order, 'AppointmentReservationAipd.getIntoAt', ''))}}</div>
      </el-form-item>
      <el-form-item label="下車時間">
        <div>{{ dateFormat(getData(order, 'AppointmentReservationAipd.getOffAt', '')) }}</div>
      </el-form-item>
      <!-- <el-form-item label="備註">
        <div>{{ order.remark || '無' }}</div>
      </el-form-item> -->
    </el-form>

    <EditReservationDialog
      v-if="showEditDialog"
      :data="order"
      :usingClassTickets="syncData.classTickets"
      @close="showEditDialog=false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import dayjs from 'dayjs'
import EditReservationDialog from '@/components/Reservation/EditReservationDialog/EditReservationDialog.vue'
import { getData } from '@/utils/object'
import { map } from 'lodash'
import { usePermissions } from '@/use/permissions'
import {checkFeature} from "@/store/modules/permission";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'OrderDetailBlock',
  components: { EditReservationDialog },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
    syncData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const useServiceResource = computed(() => {
      return checkAction('admin.resourceItem.page')
    })
    return { useServiceResource }
  },
  data: () => ({
    showEditDialog: false,
  }),
  computed: {
    ...mapGetters([
      'userFeatures',
    ]),
    isCancel () {
      return getData(this.order, 'AppointmentOrder.status') === 'cancel'
    },
    peopleCount () {
      return getData(this.order, 'AppointmentOrder.peopleCount', 1)
    },
    orersCount () {
      const orders = getData(this.order, 'AppointmentOrder.AppointmentReservations', null)
      if (!orders) return 0
      return orders.length
    },
    attachServices () {
      const services = this.order.AppointmentServiceAttaches
      if (!services) return '-'
      const servicesName = map(services, 'name')
      if (!servicesName.length) return '-'
      return servicesName.join('、')
    },
    createdAt () {
      return this.dateFormat(this.order.createdAt)
    },
    editUserPermission () {
      return !checkFeature(this.userFeatures, 'admin.appointmentReservation.update')
    },
  },
  methods: {
    getData,
    statusContent (status) {
      const statusDict = {
        cancel: '已取消',
        wait: '已預約',
        complete: '完　成',
        absence: '未　到',
      }
      return statusDict[status] || 'N/A'
    },
    dateFormat (date) {
      if (!date) return '無'
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },
    showNotSpecify (order) {
      const notSpecify = order.fromNotSpecify
      if (order.AppointmentUnit) return notSpecify ? `${order.AppointmentUnit?.name} (不指定)` : order.AppointmentUnit?.name
      else return '-'
    },
    gotoMap() {
      console.log(this.order)
      const address = getData(this.order, 'AppointmentService.address', '')
      const url = `https://www.google.com.tw/maps/search/${address}`
      window.open(url, '_blank')
    }
  },
})
</script>

<style lang="postcss" scoped>
.goto-address {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  @apply ml-2 text-primary-100 font-bold text-[14px] cursor-pointer;
}
</style>
