<template>
  <div class="member-page-tabs">
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="postcss" scoped>
/* .is-top */
::v-deep .el-tabs__header  {
  @apply bg-primary-30 rounded-sub px-[16px] py-[12px];
}

::v-deep .el-tabs__nav{
  border: none !important;
}

::v-deep .el-tabs__item {
  @apply rounded-sub text-primary-100 text-normal px-[12px] py-[4px] leading-[28.5px];
  background-color: transparent;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}
::v-deep .el-tabs__item.is-active {
  @apply bg-primary-100 rounded-sub text-white ;
  border-left: solid white 1px !important;
  border-right: solid white 1px !important;
  border-top: solid white 1px !important;
  border-bottom: solid white 1px !important;
}

</style>
