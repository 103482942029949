<template>
  <div class="order-payment-info">
    <p class="section-title text-primary-100 font-medium">訂金付款資訊</p>
    <el-form
      label-width="150px"
      label-position="left"
    >
      <el-form-item label="付款狀態">
        <div>
          <Tag style="display: inline-flex" :type="tagType(orderDepositData.status)">{{ paymentStatus }}</Tag>
          <el-button
            v-if="showRefundBtn"
            type="text"
            class="refund-btn"
            @click="goRefund"
          >
            前往退款
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="訂單總額">
        {{ orderTotalPrice }}
      </el-form-item>
      <el-form-item label="應付訂金">
        {{ depositTotalPrice }}
      </el-form-item>
      <el-form-item v-if="orderDepositData.status === 'refund'" label="退款金額">
        <span v-if="orderDepositData.refundPrice">$ {{ orderDepositData.refundPrice }}</span>
        <span v-else>-</span>
      </el-form-item>
      <el-form-item label="付款方式">
        {{ depositPaymentType }}
      </el-form-item>
      <el-form-item v-if="showPaymentCode" label="交易序號">
        <div class="flex items-center" style="gap: 14px">
          <p>{{ orderDepositData.code }}</p>
          <div class="flex items-center" @click="copyPaymentCode">
            <MaterialIcon color="var(--primary-100)" size="24">content_copy_filled</MaterialIcon>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="showMarkRefundBtn" class="flex justify-end">
      <el-button plain @click="showMarkRefundDialog=true">標記已退款</el-button>
    </div>

    <DepositRefundDialog
      v-if="showRefundDialog"
      :type="refundDialogType"
      :isManual="manualRefund"
      :orderId="orderData.id"
      :payment="{
        type: orderDepositData.paidType,
        comment: orderDepositData.paidTypeComment,
      }"
      @refunded="$emit('refresh')"
      @close="showRefundDialog=false"
    />
    <MarkRefundDialog
      v-if="showMarkRefundDialog"
      :orderData="orderData"
      :depositData="orderDepositData"
      :maxPrice="orderDepositData.depositPrice || null"
      @marked="$emit('refresh')"
      @close="showMarkRefundDialog=false"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import copy from 'clipboard-copy'
import { get } from 'lodash'
import { depositPaymentTypes, depositStatusConfig } from '@/config/deposit'
import DepositRefundDialog from './DepositRefundDialog.vue'
import MarkRefundDialog from './MarkRefundDialog.vue'

export default defineComponent({
  name: 'OrderPaymentBlock',
  components: {
    DepositRefundDialog,
    MarkRefundDialog,
  },
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  data: () => ({
    manualRefund: true,
    showRefundDialog: false,
    refundDialogType: 'wallet',
    showMarkRefundDialog: false,
  }),
  computed: {
    orderData () {
      return get(this.reservation, 'AppointmentOrder') || {}
    },
    orderTotalPrice () {
      const price = this.orderData.totalPrice
      if (!price) return '-'
      return `$ ${price}`
    },
    orderDepositData () {
      return get(this.reservation, 'AppointmentOrder.AppointmentOrderDeposit')
    },
    depositTotalPrice () {
      const price = this.orderDepositData.depositPrice
      if (!price) return '-'
      return `$ ${price}`
    },
    depositPaymentType () {
      const type = this.orderDepositData.paidType
      if (type === 'offline') {
        const comment = this.orderDepositData.paidTypeComment
        return get(depositPaymentTypes[comment], 'name')
      }
      return get(depositPaymentTypes[type], 'name')
    },
    paymentStatus () {
      const status = this.orderDepositData.status
      return get(depositStatusConfig[status], 'name')
    },
    showRefundBtn () {
      const status = this.orderDepositData.status
      const paymentType = this.orderDepositData.paidType
      const allowType = ['jkos', 'linepay', 'newebpay', 'wallet']
      if (status === 'waitRefund') {
        if (allowType.includes(paymentType)) return true
      }
      return false
    },
    showMarkRefundBtn () {
      const status = this.orderDepositData.status
      const paymentType = this.orderDepositData.paidType
      if (paymentType === 'wallet' && status === 'waitRefund') return false
      if (status === 'waitRefund') return true
      return false
    },
    showPaymentCode () {
      const paymentType = this.orderDepositData.paidType
      if (paymentType === 'offline') return false
      if (paymentType === 'free') return false
      const payment = depositPaymentTypes[paymentType]
      if (payment.type === 'third-part') return true
      return false
    },
  },
  methods: {
    copy,
    goRefund () {
      const type = this.orderDepositData.paidType
      const other = ['jkos', 'newebpay', 'linepay']
      // TOFIX
      if (type === 'wallet') this.refundDialogType = 'wallet'
      if (other.includes(type)) this.refundDialogType = 'other'
      if (type.includes('offline')) this.refundDialogType = 'mark'
      this.showRefundDialog = true
    },
    tagType (status) {
      return get(depositStatusConfig[status], 'tag')
    },
    copyPaymentCode () {
      console.log(1)
      copy(this.orderDepositData.code)
      this.$message.success('複製成功！')
      console.log(2)
    },
  },
})
</script>

<style lang="postcss" scoped>
.refund-btn {
  @apply ml-[11px];
}
</style>
