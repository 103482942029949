import { memberOriginsConfig } from '@/config/member'

export const handleMemberOrigins = ({ origin, originText }) => {
  if (!origin) return '-'
  let label = '-'
  const res = memberOriginsConfig[origin]
  if (!res) return label
  label = res.label
  if (origin === 'openapi') {
    if (originText) return `${label} (${originText})`
  }
  return label
}
