import { admin2 } from './instance'

export const FindMemberLevelConfig = async ({ shopId, level }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig/${level}`,
  })
}
export const GetMemberLevelConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig`,
  })
}

export const GetMemberLevelMemberCount = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig/memberCount`,
  })
}

export const GetMemberLevelHistory = async ({ shopId, level }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig/history`,
    params: {
      level,
    },
  })
}

export const UpdateMemberLevel = async ({
  shopId,
  level,
  name,
  validTimeUnit,
  validTime,
  levelUpCondition,
  levelUpPeriod,
  levelUpThreshold,
  levelUpSources,
  levelRemainCondition,
  levelRemainPeriod,
  levelRemainThreshold,
  levelRemainSources,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberLevelConfig`,
    data: {
      level,
      name,
      validTimeUnit,
      validTime,
      levelUpCondition,
      levelUpPeriod,
      levelUpThreshold,
      levelUpSources,
      levelRemainCondition,
      levelRemainPeriod,
      levelRemainThreshold,
      levelRemainSources,
    },
  })
}

export const MemberLevelExpiredAt = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig/expiredAt`,
  })
}

export const FindMemberLevelRecord = async ({ shopId, id, start, limit }) => {
  return admin2({
    mthod: 'GET',
    url: `/${shopId}/member/${id}/levelRecord`,
    params: {
      start,
      limit,
    },
  })
}
export const FindMemberLevelRecordCount = async ({ shopId, id }) => {
  return admin2({
    mthod: 'GET',
    url: `/${shopId}/member/${id}/levelRecord`,
  })
}
export const FindMemberCurrentLevelRecord = async ({
  shopId,
  id,
  start,
  limit,
}) => {
  return admin2({
    mthod: 'GET',
    url: `/${shopId}/member/${id}/levelRecord/current`,
    params: {
      start,
      limit,
    },
  })
}

export const FindMemberLevelCount = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberLevelConfig/memberCount`,
  })
}
