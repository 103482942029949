export default {
  data: () => ({
    dialogType: 'create',
    showDialog: false,
    deleteDialog: false,
  }),
  methods: {
    changeDialogTitle (dict) {
      return dict[this.dialogType]
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
