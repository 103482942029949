import { ExportExcel } from '@/utils/excel'
export default {
  data: () => ({
    // Export
    showExportOptions: false,
    exportting: false,
    exportError: false,
    exportData: null,
    exportTotal: 0,
    exportPercentage: 0,
    showExportDialog: false,
  }),
  methods: {
    ExportExcel,
    resetExport () {
      this.showExportDialog = false
      this.exportting = false
      this.exportError = false
      this.exportData = null
      this.exportPercentage = 0
    },
  },
}
