<template>
  <div>
    <el-tooltip placement="right">
      <div slot="content" :style="computedStyle">
        <slot />
      </div>
      <span class="material-icons">help_outline</span>
    </el-tooltip>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'TipInfo',
  props: {
    width: { type: [String, Number], default: 'auto' },
  },
  setup (props) {
    const computedStyle = computed(() => {
      return {
        width: props.width ? `${props.width}px` : 'auto',
      }
    })
    return { computedStyle }
  },
})
</script>

<style lang="postcss" scoped>

</style>
