import { admin2 } from '@/api/instance'

// => 取得抽獎紀錄
export const GetMemberGameRecord = async ({
  shopId,
  start,
  limit,
  createdAtStart,
  createdAtEnd,
  MemberGameId,
  LotteryAwardId,
  MemberId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameRecord`,
    params: {
      start,
      limit,
      createdAtStart,
      createdAtEnd,
      MemberGameId,
      LotteryAwardId,
      MemberId,
    },
  })
  return res
}

// => 取得抽獎紀錄總數
export const GetMemberGameRecordCount = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  MemberGameId,
  LotteryAwardId,
  MemberId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameRecord/count`,
    params: {
      createdAtStart,
      createdAtEnd,
      MemberGameId,
      LotteryAwardId,
      MemberId,
    },
  })
  return res
}

// => 查找抽獎紀錄
export const FindMemberGameRecord = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameRecord/${id}`,
  })
  return res
}

// => 查找抽獎紀錄統計
export const MemberGameRecordStatistical = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  MemberGameId,
  LotteryAwardId,
  MemberId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameRecord/statistical`,
    params: {
      createdAtStart,
      createdAtEnd,
      MemberGameId,
      LotteryAwardId,
      MemberId,
    },
  })
  return res
}
