import { render, staticRenderFns } from "./OrderDetailBlock.vue?vue&type=template&id=6f08b28e&scoped=true"
import script from "./OrderDetailBlock.vue?vue&type=script&lang=js"
export * from "./OrderDetailBlock.vue?vue&type=script&lang=js"
import style0 from "./OrderDetailBlock.vue?vue&type=style&index=0&id=6f08b28e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f08b28e",
  null
  
)

export default component.exports