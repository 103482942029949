<template>
  <el-dialog
    title="標記已退款"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :rules="formRules" :model="formData" label-position="top">
      <el-form-item label="記錄退款金額" prop="markPrice">
        <el-input v-model="formData.markPrice" placeholder="請輸入金額" />
      </el-form-item>
    </el-form>
    <div class="hint" style="width: 560px">
      請注意退款金額僅能輸入一次，不可更改，且標記已退款後付款狀態將自動更新為已退款並向預約者發送退款通知
    </div>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="markRefund"
      >
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { OrderDepositRefund } from '@/api/reservation'
import { manualRefundPaymentType } from '@/config/deposit'
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import { computed } from 'vue'

export default {
  name: 'DepositRefundDialog',
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    depositData: {
      type: Object,
      default: () => ({}),
    },
    maxPrice: Number,
  },
  setup (props) {
    const formRules = computed(() => {
      const rules = {
        markPrice: [noEmptyRules(), isDigitRules()],
      }
      if (props.maxPrice) {
        rules.markPrice.push(rangeRules(1, props.maxPrice))
      }
      return rules
    })
    return { formRules }
  },
  data: () => ({
    loading: false,
    formData: {
      markPrice: null,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  methods: {
    async markRefund () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const paidType = get(this.depositData, 'paidType')
      const comment = get(this.depositData, 'paidTypeComment')

      const type = get(manualRefundPaymentType[paidType], 'value')

      try {
        this.loading = true
        await OrderDepositRefund({
          shopId: this.shop,
          id: this.orderData.id,
          refundType: type,
          refundTypeComment: comment || undefined,
          refundPrice: this.formData.markPrice,
        })
        this.$message.success('已標記退款！')
        this.$emit('marked')
        this.$emit('close')
        this.loading = false
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.hint {
  @apply text-danger break-all whitespace-normal text-sm w-auto leading-[17.38px] mt-[12px];
}
</style>
