import { admin2 } from '@/api/instance'

export const CreatePospalConfig = async ({
  shopId,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPal`,
    data: {
      config,
    },
  })
}

export const UpdatePospalConfig = async ({
  shopId,
  config,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/pospal`,
    data: {
      config,
    },
  })
}

export const FindPospalWallet = async ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPalMemberWallet`,
    data: {
      memberId,
    },
  })
}

export const GetPospalClientConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPalClientConfig`,
    data: {},
  })
}

export const SyncPospalMember = async ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/syncPosPalClientMember`,
    data: {
      memberId,
    },
  })
}

export const CheckPospalMemberSync = ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/retrievePosPalClientMember`,
    data: {
      memberId,
    },
  })
}
