<template>
  <el-drawer
    title="推薦紀錄"
    :visible="true"
    direction="rtl"
    class="member-invite-record"
    @close="$emit('close')"
  >
    <div class="content-wrapper">
      <el-form label-position="left" label-width="150px">
        <el-form-item label="推薦人名稱">
          {{ displayData.inviter }}
        </el-form-item>
        <el-form-item label="推薦人手機號碼">
          {{ displayData.inviterPhone }}
        </el-form-item>
      </el-form>

      <el-divider />

      <el-form label-position="left" label-width="150px">
        <el-form-item label="成功推薦次數">
          {{ displayData.successCount }}
        </el-form-item>
        <el-form-item label="推薦連結點擊次數">
          {{ displayData.viewCount }}
        </el-form-item>
        <el-form-item label="推薦紀錄" />
      </el-form>

      <div v-loading="loading">
        <el-steps v-if="logs.length" direction="vertical" :active="logs.length" :space="81">
          <el-step v-for="log in logs" :key="log.id">
            <template #description>
              <MemberInviteRecordItem :record="log" />
            </template>
            <template #icon>
              <div class="w-full h-full bg-primary-100 rounded-full w-[10px] h-[10px]" />
            </template>
          </el-step>
        </el-steps>

        <EmptyBlock v-else />
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { FindMemberInviteRecordByRefereeId, GetMemberInviteRecord, GetMemberInviteRecordCount } from '@/api/memberInvite'
import MemberInviteRecordItem from './components/MemberInviteRecordItem.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'MemberInviteRecord',
  components: { EmptyBlock, MemberInviteRecordItem },
  props: {
    member: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { simpleFetch, fetchAll } = useFetch()
    const memberRefereeId = computed(() => get(props.member, 'MemberReferralCode.id'))
    const currentLevelData = ref({})
    const logs = ref([])
    const loading = ref(false)

    const displayData = computed(() => {
      const data = currentLevelData.value
      return {
        inviter: get(data, 'Referrer.UserInfo.name', '-'),
        inviterPhone: get(data, 'Referrer.UserInfo.phone', '-'),

        viewCount: get(props.member, 'MemberReferralCode.viewCount'),
        successCount: get(props.member, 'MemberReferralCode.successCount'),
      }
    })

    const displayLog = computed(() => {
      return []
    })

    onMounted(async () => {
      loading.value = true
      await simpleFetch(FindMemberInviteRecordByRefereeId, {
        shopId: shopId.value,
        refereeId: get(props.member, 'id'),
      }, (res) => {
        currentLevelData.value = res
      })

      fetchAll(GetMemberInviteRecord, { shopId: shopId.value, ReferrerId: get(props.member, 'id') }, (res) => {
        logs.value = res
      })

      loading.value = false
    })

    return { displayData, displayLog, logs, loading }
  },
})
</script>

  <style lang="postcss" scoped>
  .content-wrapper {
      @apply flex flex-col h-full;
      @apply px-[24px];
  }
  ::v-deep .el-steps {
    @apply mt-[12px]
  }
  ::v-deep .el-step.is-vertical .el-step__head {
    @apply w-[30px] top-[9px]
  }
  ::v-deep .el-step.is-vertical .el-step__main {
    @apply pl-0
  }

  ::v-deep .el-step.is-vertical .el-step__line {
    @apply left-[4px] w-[1px]
  }
  ::v-deep .el-form-item {
      @apply mb-0;
  }

  ::v-deep .el-step__description {
    @apply pr-0 text-gray-100 top-[-4px] relative;
  }

  ::v-deep .el-step__icon.is-text {
    @apply top-[-13px] h-[10px] w-[10px] bg-primary-100;
  }

  ::v-deep .el-step__icon-inner {
    @apply hidden;
  }
  </style>
