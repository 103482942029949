<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-if="type === 'other'">
      是否前往第三方金流後台進行退款？<br>
      小提醒：請記得複製交易序號以利後續退款
    </div>

    <el-form v-if="type === 'wallet'" ref="form" :model="formData" :rules="formRules">
      <el-form-item v-if="type === 'wallet'" label="請輸入欲返還的儲值金金額" prop="walletPrice">
        <el-input v-model="formData.walletPrice" placeholder="請輸入金額" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="refund"
      >
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { refundLinkConfig, manualRefundPaymentType } from '@/config/deposit'
import { OrderDepositRefund } from '@/api/reservation'
import { get } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'DepositRefundDialog',
  props: {
    type: {
      type: String,
      default: 'wallet',
    },
    payment: {
      type: Object,
      default: () => ({}),
    },
    isManual: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: false,
    formData: {
      walletPrice: null,
    },
    formRules: {
      walletPrice: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    dialogTitle () {
      let label = ''
      if (this.type === 'wallet') label = '返還儲值金'
      if (this.type === 'other') label = '提醒'
      return label
    },
  },
  methods: {
    async refund () {
      if (this.type === 'wallet') {
        await this.walletRefund()
        return
      }
      const link = refundLinkConfig[this.payment.type]
      window.open(link)
      this.$emit('close')
    },

    async walletRefund () {
      if (!await formUtils.checkForm(this.$refs.form)) return

      let type = this.payment.type
      if (this.isManual) type = get(manualRefundPaymentType[type], 'value')
      this.loading = true

      const [, err] = await OrderDepositRefund({
        shopId: this.shop,
        id: this.orderId,
        refundType: type,
        refundTypeComment: this.payment.comment || undefined,
        refundPrice: this.formData.walletPrice,
      })
      if (err) {
        console.log(err)
        this.$message.error(err)
        this.loading = false
        return
      }
      this.$message.success('已成功退款！')
      this.$emit('refunded')
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style lang="postcss" scoped>
</style>
