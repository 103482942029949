<template>
  <div class="lottery-history-tab">
    <el-table :data="lotteryHistoryList">
      <EmptyBlock slot="empty" />
      <el-table-column label="日期" align="center">
        <template slot-scope="scope">
          <div>
            {{ dateFormat(scope.row.createdAt) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="awardType" label="獎項類型" align="center">
        <template slot-scope="scope">
          <div>
            {{ rewardTypes[scope.row.awardType] ? rewardTypes[scope.row.awardType].name : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="參與抽獎名稱"
        prop="MemberGame.name"
        align="center"
      >
        <template slot-scope="scope">
          {{ getData(scope.row, 'MemberGame.name') || '' }}
        </template>
      </el-table-column>
      <el-table-column label="獲得獎項" prop="awardName" align="center" />
    </el-table>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="lotteryHistoryCount"
      @pageChange="refresh"
    />
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import tableMixin from '@/mixin/table'
import {
  GetMemberGameRecord,
  GetMemberGameRecordCount,
} from '@/api/lottery/memberGameRecord'
import { getData } from '@/utils/object'
import { RewardTypesShowConfig } from '@/config/lottery'

export default {
  name: 'LotteryHistoryTab',
  components: { EmptyBlock },
  mixins: [tableMixin],
  props: ['memberId'],
  data: () => ({
    lotteryHistoryList: [],
    lotteryHistoryCount: 0,
    rewardTypes: RewardTypesShowConfig,
  }),

  async mounted () {
    await this.refresh()
  },
  methods: {
    getData,
    changePage () {},

    async refresh () {
      await this.getMemberGameRecord()
      await this.getMemberGameRecordCount()
    },

    async getMemberGameRecord () {
      const [res, err] = await GetMemberGameRecord({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        MemberId: this.memberId,
      })
      if (err) return this.$message.error(err)
      this.lotteryHistoryList = res
    },

    async getMemberGameRecordCount () {
      const [res, err] = await GetMemberGameRecordCount({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        MemberId: this.memberId,
      })
      if (err) return this.$message.error(err)
      this.lotteryHistoryCount = res.count
    },
  },
}
</script>

<style lang="postcss" scoped></style>
