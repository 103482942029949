import { FindCompanyContactMenu } from '@/api/company/contactMenu'
import { ref, computed } from 'vue'
import { get, find } from 'lodash'
import { usePermissions } from '@/use/permissions'

export const useCompany = () => {
  const contactMenuInfo = ref(null)
  const { checkAction } = usePermissions()

  const actionPermission = computed(() => {
    return !!checkAction('admin.shopCompanyDirectory.page')
  })

  const orgTaxId = (customUserSetting) => {
    return find(customUserSetting, { type: 'companyDirectoryFirmTaxId' })
  }

  const useCompanyContactMenu = computed(() => {
    const permission = !!checkAction('admin.shopCompanyDirectory.page')
    const registered = !!get(contactMenuInfo.value, 'id')
    return permission && registered
  })

  const getShopContactMenuInfo = async (shopId) => {
    if (!actionPermission.value) return
    const [res, err] = await FindCompanyContactMenu(shopId)
    if (err) {
      window.$message.error(err)
      return
    }
    contactMenuInfo.value = res
  }

  return {
    orgTaxId,
    getShopContactMenuInfo,
    contactMenuInfo,
    useCompanyContactMenu,
  }
}
