import { admin2 } from './instance'

export const GetReservationTicket = async ({
  shopId,
  AppointmentOrderId,
  AppointmentReservationId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentReservationTicket`,
    params: {
      start,
      limit,
      AppointmentOrderId,
      AppointmentReservationId,
    },
  })
}
export const GetReservationTicketCount = async ({
  shopId,
  AppointmentOrderId,
  AppointmentReservationId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentReservationTicket/count`,
    params: {
      AppointmentOrderId,
      AppointmentReservationId,
    },
  })
}
export const UpdateReservationTicketStatus = async ({
  shopId, id, status,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentReservationTicket/${id}/status`,
    data: {
      status,
    },
  })
}
