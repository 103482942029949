<template>
  <div class="order-info">
    <el-form v-if="order" label-width="150px" label-position="left">
      <!-- <p class="section-title text-primary-100 font-medium">{{ index }}.預約訂單</p> -->
      <el-form-item label="操作">
        <el-button
          class="primary-btn underline"
          type="text"
          @click="showEditDialog = true"
        >
          編輯訂單資訊
        </el-button>
      </el-form-item>
      <el-form-item label="訂單編號">
        <div>{{ order.code }}</div>
      </el-form-item>
      <el-form-item label="訂單狀態">
        <Tag
          v-if="getData(order, 'AppointmentOrder.status', '')"
          style="display: inline-flex;"
          :type="orderStatus(getData(order, 'AppointmentOrder.status'), 'tag')"
          disable-transitions
        >
          {{ orderStatus(getData(order, 'AppointmentOrder.status'), 'name') }}
          <!-- {{order.status}} -->
        </Tag>
      </el-form-item>

      <el-form-item label="會員類別">
        <div>
          <span class="mr-3">{{ order.Member ? '會員' : '非會員' }}</span>
          <el-button
            v-if="order.Member && $route.name !== 'MemberProfile'"
            type="text"
            class="table-opt-edit"
            @click="$router.push(`/members/member-profile/${order.MemberId}`)"
          >
            前往查看
          </el-button>
        </div>
      </el-form-item>

      <el-form-item label="預約人數">
        <div>{{ peopleCount }}</div>
      </el-form-item>

      <el-form-item label="預約者姓名">
        <div>{{ getData(order, 'AppointmentOrder.userName', '') }}</div>
      </el-form-item>
      <el-form-item label="預約者生日">
        <div>{{ getData(order, 'Member.UserInfo.birthday') || '-' }}</div>
      </el-form-item>
      <el-form-item label="預約者電話">
        <div>{{ getData(order, 'AppointmentOrder.userPhone', '') }}</div>
      </el-form-item>
      <el-form-item label="個案地點">
        <div class="flex items-center">
          <div style="max-width: 320px; line-height: normal"> {{ getData(order, 'AppointmentOrder.userAddress', '') }}</div>
          <span class="goto-address" @click="gotoMap">地圖</span>
          <img alt="" src="@/assets/icon/tabler-location.svg" width="14px" class="cursor-pointer" @click="gotoMap">
        </div>
      </el-form-item>

      <el-form-item label="會員備註">
        <div style="width: 100%; word-wrap: break-word">
          {{ getData(order, 'AppointmentOrder.userComment') || '無' }}
        </div>
      </el-form-item>
      <el-form-item label="額外備註">
        <div>{{ getData(order, 'AppointmentOrder.adminComment') || '無' }}</div>
      </el-form-item>
      <el-form-item label="預約來源">
        <div>{{ reservationOrigins }}</div>
      </el-form-item>
    </el-form>

    <EditReservationStatusDialog
      v-if="showEditDialog"
      :data="order"
      @close="showEditDialog = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import EditReservationStatusDialog from '@/components/Reservation/EditReservationDialog/EditReservationStatusDialog.vue'
import { reservationOrderOrigins } from '@/utils/reservation'
import { get } from 'lodash'
import { getData } from '@/utils/object'
import { orderStatusConfig } from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationOrderInfoBlock',
  components: {
    EditReservationStatusDialog,
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  data: () => ({
    showEditDialog: false,
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userPlanLimit',
      'userFeatures',
      'userPlanFeature',
    ]),
    isCancel () {
      return getData(this.order, 'AppointmentOrder.status') === 'cancel'
    },
    peopleCount () {
      return get(this.order, 'AppointmentOrder.peopleCount', 1)
    },
    reservationOrigins () {
      const notSpecify = this.order.fromNotSpecify
      const origin = get(this.order, 'AppointmentOrder.origin', '')
      const originText = get(this.order, 'AppointmentOrder.originText', '')
      return reservationOrderOrigins({ notSpecify, origin, originText })
    },
    useMemberAddress () {
      return this.checkAction('adminView.member.findUserInfoMoreAddress')
    },
  },
  methods: {
    getData,
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    orderStatus (status, attr) {
      return get(orderStatusConfig[status], attr)
    },
    statusContent (status) {
      const statusDict = {
        cancel: '已取消',
        wait: '已預約',
        complete: '完　成',
        absence: '未　到',
      }
      return statusDict[status] || 'N/A'
    },
    gotoMap() {
      console.log(this.order)
      const address = getData(this.order, 'AppointmentOrder.userAddress', '')
      const url = `https://www.google.com.tw/maps/search/${address}`
      window.open(url, '_blank')
    }
  },
}
</script>

<style lang="postcss" scoped>
.goto-address {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  @apply ml-2 text-primary-100 font-bold text-[14px] cursor-pointer;
}
</style>
