<template>
  <div class="member-history-tab">
    <div class="text-right mb-[20px]">
      <span>
        <el-tooltip placement="left" :appendToBody="true">
          <div slot="content">
            <div class="w-[180px]">
              欲上傳會員個資內容或檔案，須經當事人之同意，得依個人資料保護法進行個人資料之蒐集、處理及利用，並留意不可未經個人資料當事人同意，故意或過失洩漏個人資料。
            </div>
          </div>
          <span class="material-icons" style="font-size: 16px; margin-left: 5px">
            help_outline
          </span>
        </el-tooltip>
        上傳個資提醒
      </span>
      <el-button
        type="primary"
        class="font-medium text-normal"
        @click=";(showDialog = true), (dialogType = 'create')"
      >
        新增歷程
      </el-button>
    </div>

    <!-- ANCHOR template 服務歷程表格 -->
    <el-table v-loading="loading" :data="historyList">
      <EmptyBlock slot="empty" />
      <el-table-column prop="Images" label="圖片" width="150" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto;"
            width="90"
            :src="imgUrl(scope.row.Images[0] || null)"
            alt=""
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="fileName"
        label="檔案名稱"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.FileId ? scope.row.fileName : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="memberNote"
        label="給會員的說明"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="note"
        label="筆記"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createdAt"
        label="建立日期"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="showMember" label="公開顯示" align="center">
        <template slot-scope="scope">
          <Tag
            disable-transitions
            :type="scope.row.showMember ? 'action' : 'info'"
          >
            {{ scope.row.showMember ? '是' : '否' }}
          </Tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-popover placement="top">
            <div v-if="selectRow" class="table-edit-btn-popover">
              <el-button
                type="text"
                class="table-opt-edit"
                icon="el-icon-search"
                @click="
                  ;(showDialog = true),
                    syncFormData(scope.row),
                    (dialogType = 'info')
                "
              >
                檢視
              </el-button>
              <el-button
                type="text"
                class="table-opt-edit"
                icon="el-icon-edit-outline"
                @click="
                  ;(showDialog = true),
                    syncFormData(scope.row),
                    (dialogType = 'update')
                "
              >
                編輯
              </el-button>
              <el-button
                type="text"
                class="table-opt-del"
                icon="el-icon-delete"
                @click="deleteDialog = true"
              >
                刪除
              </el-button>
            </div>
            <el-button
              slot="reference"
              type="text"
              class="btn-type-edit-row"
              icon="el-icon-setting"
              @click="selectRow = scope.row"
            />
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="historyCount"
      @pageChange="refresh"
    />

    <!-- ANCHOR template 新增歷程-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"

      @close="resetForm"
    >
      <el-form
        ref="form"
        v-loading="formLoading"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="公開顯示" prop="showMember">
          <Tag
            v-if="showInput(['info'])"
            :type="formData.showMember ? 'action' : 'info'"
          >
            {{ formData.showMember ? '是' : '否' }}
          </Tag>
          <el-switch
            v-if="showInput(['create', 'update'])"
            v-model="formData.showMember"
            active-color="#2BB8C1"
            inactive-color="#AEAEAE"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>

        <el-form-item label="圖片" prop="Images">
          <div class="flex gap-10">
            <div
              v-for="(img, index) in formData.Images"
              :key="img.id"
              class="img-wrapper"
            >
              <i
                v-if="showInput(['create', 'update'])"
                class="el-icon-circle-close remove-btn"
                @click="removeImg(index)"
              />

              <viewer
                v-if="showInput(['info'])"
                :images="[imgUrl(img)]"
                :options="viewerOptions"
              >
                <img :src="imgUrl(img)" style="cursor: pointer;">
              </viewer>

              <img
                v-if="showInput(['create', 'update'])"
                :src="imgUrl(img)"
                alt=""
              >
            </div>
            <UploadButton
              v-if="
                showInput(['create', 'update']) && formData.Images.length < 3
              "
              style="width: 90px; height: 90px;"
              :img="null"
              @change="imageChange"
            />
          </div>
        </el-form-item>

        <el-form-item
          v-if="formData.showMember"
          label="給會員的說明"
          prop="memberNote"
        >
          <div v-if="showInput(['info'])">{{ formData.memberNote }}</div>
          <el-input
            v-if="showInput(['create', 'update'])"
            v-model="formData.memberNote"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="文字"
            maxlength="1000"
            show-word-limit
          />
        </el-form-item>

        <el-form-item label="筆記" prop="note">
          <div v-if="showInput(['info'])">{{ formData.note }}</div>
          <el-input
            v-if="showInput(['create', 'update'])"
            v-model="formData.note"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            placeholder="文字"
            maxlength="1000"
            show-word-limit
          />
        </el-form-item>
        <div class="pt-2 pb-4">
          <hr class="">
        </div>
        <el-form-item
          v-if="showInput(['create', 'update'])"
          label="檔案上傳"
        >
          <InputFile
            :modelValue.sync="formData.file"
            accept="application/pdf"
            @errorFileType="$message.error('檔案格式錯誤')"
            @errorFileSize="$message.error('檔案大小超過 10 mb')"
            @update:modelValue="onUpdateFile"
          >
            <template v-slot:tip="{ size }">
              <div class="file-tips">
                <p class="mb-1">
                  檔案大小必須小於 {{ size }}MB，檔案格式僅支援 PDF
                </p>
                <p>
                  *檔案若為機密個資，建議將pdf設定密碼鎖再上傳，並將密碼提示新增至檔案說明
                </p>
              </div>
            </template>
          </InputFile>
        </el-form-item>
        <el-form-item label="檔案名稱" prop="fileName">
          <div v-if="showInput(['info'])">{{ formData.fileName }}</div>
          <el-input
            v-if="showInput(['create', 'update'])"
            v-model="formData.fileName"
            maxlength="20"
            placeholder="請輸入文字"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="檔案說明" prop="fileDescription">
          <div v-if="showInput(['info'])">{{ formData.fileDescription }}</div>
          <el-input
            v-if="showInput(['create', 'update'])"
            v-model="formData.fileDescription"
            class="input-file-desc"
            maxlength="50"
            placeholder="請輸入文字"
            show-word-limit
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          v-if="showInput(['create', 'update'])"
          class="btn"
          plain
          @click=";(showDialog = false), (selectRow = null)"
        >
          返回
        </el-button>
        <el-button
          class="btn"
          type="primary"
          :disabled="noContent"
          @click="handleConfirm"
        >
          {{ dialogBtn }}
        </el-button>
      </div>
    </el-dialog>

    <ImageCropper
      v-if="uploadDialog"
      :image="image"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteHistory(), refresh(), (deleteDialog = false)"
    />
    <WarningDialog
      v-if="warnDialog"
      content="尚無選取檔案，會員將看不到檔案名稱、檔案說明"
      cancelBtnString="繼續編輯"
      @close="onWarnDialog(false)"
      @confirm="onWarnDialog(true)"
    />
  </div>
</template>

<script>
import {
  CreateHistory,
  GetHistory,
  GetHistoryCount,
  FindHistory,
  UpdateHistory,
  DeleteHistory,
} from '@/api/memberHistory'
import { imgSrc, extractList } from '@/utils/helper'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { noEmptyRules, lengthRules } from '@/validation'
import MixinFunc from '@/components/MixinFunc.vue'
import InputFile from '@/components/Form/InputFile.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { UploadFile } from '@/api/file'
import { ref } from 'vue'
export default {
  name: 'MemberHistoryTab',
  components: {
    DeleteDialog,
    ImageCropper,
    UploadButton,
    EmptyBlock,
    InputFile,
    WarningDialog,
  },
  mixins: [MixinFunc],
  props: ['member'],
  setup () {
    const warnDialog = ref(false)

    const waitDialog = ref(null)

    const onWarnDialog = (value) => {
      warnDialog.value = false
      if (waitDialog.value) {
        waitDialog.value(value)
        waitDialog.value = null
      }
    }
    return {
      warnDialog,
      waitDialog,
      onWarnDialog,
    }
  },
  data: () => ({
    loading: false,
    formLoading: false,
    deleteDialog: false,
    showDialog: false,
    uploadDialog: false,
    dialogType: '',
    selectRow: null,

    historyCount: 0,
    historyList: [],

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    image: null,
    formData: {
      showMember: true,
      Images: [],
      memberNote: '',
      note: '',
      file: {},
      fileName: '',
      fileDescription: '',
    },
    fileId: '',
    viewerOptions: {
      toolbar: false,
      navbar: false,
      title: false,
    },
  }),
  computed: {
    shop () {
      return this.$store.getters.shop
    },

    startIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    imageIdList () {
      return extractList('id', this.formData.Images)
    },

    dialogTitle () {
      const type = this.dialogType
      let word = ''
      if (type === 'create') word = '新增服務歷程'
      if (type === 'update') word = '編輯服務歷程'
      if (type === 'info') word = '查看服務歷程'
      return word
    },

    dialogBtn () {
      const type = this.dialogType
      let word = ''
      if (type === 'create') word = '新增'
      if (type === 'update') word = '儲存'
      if (type === 'info') word = '關閉'
      return word
    },

    noContent () {
      const form = this.formData
      let disable = false
      const noImgs = form.Images.length === 0
      const noMNote = form.memberNote === ''
      const noNote = form.note === ''
      const noFile = !form.file?.url && !form.file?.id //
      if (noImgs && noMNote && noNote && noFile) disable = true
      return disable
    },
    formRules () {
      const file = this.formData.file
      if (file.url) {
        return {
          fileName: [noEmptyRules(), lengthRules(0, 20)],
          fileDescription: [lengthRules(0, 50)],
          // images: [noEmptyRules()],
          // memberNote: [noEmptyRules()],
          // note: [noEmptyRules()],
        }
      }
      return {
        fileName: [lengthRules(0, 20)],
        fileDescription: [lengthRules(0, 50)],
      }
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async refresh () {
      this.loading = true
      await this.getHistory()
      await this.getHistoryCount()
      this.loading = false
    },
    showInput (arr) {
      return arr.includes(this.dialogType)
    },

    imageChange (img) {
      this.image = img

      this.uploadDialog = true
    },

    getImage (img) {
      this.formData.Images.push(img)
      this.image = img
      this.uploadDialog = false
    },

    imgUrl (img) {
      return imgSrc(300, img)
    },

    removeImg (index) {
      this.formData.Images.splice(index, 1)
    },

    syncFormData (data) {
      const _data = JSON.parse(JSON.stringify(data))
      if (_data.FileId && _data.fileName) {
        _data.file = {
          filename: _data.fileName,
          id: _data.FileId,
        }
      } else {
        _data.file = {}
      }
      _data.fileName = _data.fileName || ''
      _data.fileDescription = _data.fileDescription || ''
      this.formData = _data
      this.fileId = ''
    },

    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        showMember: true,
        Images: [],
        memberNote: '',
        note: '',
        file: {},
        fileName: '',
        fileDescription: '',
      }
      this.fileId = ''
    },
    onUpdateFile (value) {
      const defaulFileName = value.raw?.name
      if (defaulFileName && !this.formData.fileName) {
        this.formData.fileName = defaulFileName
      }
    },
    // ANCHOR 送出服務歷程
    async handleConfirm () {
      const type = this.dialogType

      // eslint-disable-next-line no-useless-return
      if (!(await formUtils.checkForm(this.$refs.form))) return

      this.formLoading = true
      const { file, fileName, fileDescription } = this.formData
      const res = await this.uploadFile({ ...file, name: fileName })

      if (!res) {
        this.formLoading = false
        return
      }
      this.fileId = res.id
      if ((fileName || fileDescription) && !this.fileId) {
        this.warnDialog = true
        const resWarn = await new Promise(resolve => {
          this.waitDialog = resolve
        })
        if (!resWarn) {
          this.formLoading = false
          return
        }
      }
      if (type === 'create') await this.createHistory()
      if (type === 'update') await this.updateHistory()
      this.showDialog = false
      this.resetForm()
      this.formLoading = false
    },

    async createHistory () {
      try {
        const form = this.formData
        await CreateHistory({
          shopId: this.shop,
          member: this.member.id,
          showMember: form.showMember,
          images: this.imageIdList,
          memberNote: form.showMember ? form.memberNote : undefined,
          note: form.note,
          file: this.fileId || null,
          fileName: form.fileName || undefined,
          fileDescription: form.fileDescription || undefined,
        })
        await this.refresh()
        this.$message.success('新增歷程成功 !')
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async getHistory () {
      try {
        const res = await GetHistory({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.limit,
          MemberId: this.member.id,
        })

        this.historyList = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async getHistoryCount () {
      try {
        const res = await GetHistoryCount({
          shopId: this.shop,
          MemberId: this.member.id,
        })
        this.historyCount = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async findHistory () {
      try {
        const res = await FindHistory({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.formData = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async updateHistory () {
      try {
        if (!(await formUtils.checkForm(this.$refs.form))) return
        const form = this.formData
        await UpdateHistory({
          shopId: this.shop,
          id: this.selectRow.id,
          showMember: form.showMember,
          images: this.imageIdList,
          memberNote: form.showMember ? form.memberNote : undefined,
          note: form.note,
          file: this.fileId || null,
          fileName: form.fileName || undefined,
          fileDescription: form.fileDescription || undefined,
        })
        await this.refresh()
        this.$message.success('更新歷程成功 !')
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async deleteHistory () {
      try {
        await DeleteHistory({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        await this.refresh()
        this.$message.success('刪除歷程成功 !')
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },
    // ANCHOR 上傳服務歷程檔案
    async uploadFile (payload) {
      const { raw, name } = payload
      try {
        let res, err
        if (raw) {
          [res, err] = await UploadFile({
            name,
            type: 'memberHistory',
            file: raw,
          })
        } else {
          return payload
        }
        if (err) {
          this.$message.error(err)
          return false
        }
        return res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.img-wrapper {
  position: relative;
  width: 90px;
  border-radius: 4px;

  img {
    border-radius: 4px;
  }
}

.btn {
  width: 140px;
}

.remove-btn {
  border-radius: 4px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}

.file-tips {
  line-height: 1.5;
  @apply text-gray-80;
}
::v-deep .el-dialog {
  min-width: 650px !important;
}

::v-deep .input-file-desc .el-input__inner {
  @apply pr-[48px];
}
</style>
