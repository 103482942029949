import { admin2 } from '@/api/instance'

// => 創建使用券紀錄
export const CreateCouponRecord = async ({
  shopId,
  memberId,
  couponId,
  originName,
  message,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/couponRecord`,
    data: {
      memberId,
      couponId,
      originName,
      message,
    },
  })
  return res
}

// => 取得使用券紀錄
export const GetCouponRecord = async ({
  shopId,
  start,
  limit,
  MemberId,
  sort,
  status,
  dateRangeType,
  startAt,
  endAt,
  code,
  exchangeCode,
  type,
  isUsed,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/couponRecord`,
    params: {
      start,
      limit,
      MemberId,
      sort,
      status,
      dateRangeType,
      startAt,
      endAt,
      code,
      exchangeCode,
      type,
      isUsed,
    },
  })
  return res
}

// => 取得使用券紀錄總數
export const GetCouponRecordCount = async ({
  shopId,
  MemberId,
  status,
  dateRangeType,
  startAt,
  endAt,
  code,
  exchangeCode,
  type,
  isUsed,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/couponRecord/count`,
    params: {
      MemberId,
      status,
      dateRangeType,
      startAt,
      endAt,
      code,
      exchangeCode,
      type,
      isUsed,
    },
  })
  return res
}

// => 查找使用券紀錄
export const FindCouponRecord = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/couponRecord/count/${id}`,
  })
  return res
}
// => 使用使用券
export const UseCoupon = async ({ shopId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/couponRecord/${id}/use`,
  })
}
