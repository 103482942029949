<template>
  <Tag
    size="small"
    type="info"
    class="tag__more"
    style="background: white"
  >
    <div class="flex items-center" @click="showMoreTag">
      <span>更多</span>
      <span class="material-icons text-sub rotate-180">
        expand_more
      </span>
    </div>
  </Tag>
</template>

<script>
import { defineComponent } from 'vue'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'moreTag',
  components: { Tag },
  props: {
    showMoreTag: { type: Function, default: () => {} },
  },
})
</script>
