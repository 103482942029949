import { admin2 } from '@/api/instance'

// 註冊企業通訊錄服務
export const RegisterCompanyContactMenu = async ({
  shopId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCompanyDirectory/register`,
    data: {},
  })
}

// 查找店家註冊資訊
export const FindCompanyContactMenu = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectory`,
  })
}

// 建立店家通訊錄
export const CreateShopContactMenu = async ({
  shopId,
  taxId,
  name,
  customize,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCompanyDirectoryFirm`,
    data: {
      taxId,
      name,
      customize,
    },
  })
}

// 查找店家通訊錄
export const GetShopContactMenu = async ({
  shopId,
  start,
  limit,
  search,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm`,
    params: {
      start,
      limit,
      search,
      memberId,
    },
  })
}

// 查找店家通訊錄
export const GetShopContactMenuCount = async ({
  shopId, search,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/count`,
    params: {
      search,
      memberId,
    },
  })
}

// 查找店家通訊錄
export const FindShopContactMenu = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}`,
  })
}

// 更新店家通訊錄
export const UpdateShopContactMenu = async ({
  shopId,
  id,
  taxId,
  name,
  customize,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}`,
    data: {
      taxId,
      name,
      customize,
    },
  })
}

// 刪除店家
export const DeleteShopContactMenu = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}`,
  })
}

// 查找店家註冊資訊
export const SimpleFindCompanyContactMenu = async ({
  shopId,
  limit,
  start,
  search,
  memberId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/simpleFind`,
    params: {
      limit,
      start,
      search,
      memberId,
    },
  })
}

// 查找店家註冊資訊
export const GetShopContactMenuSchema = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/schema`,
  })
}

export const GetShopContactMembers = async ({
  shopId,
  id,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}/members`,
    params: {
      start,
      limit,
    },
  })
}

export const GetShopContactMembersCount = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}/members/count`,
  })
}

export const AddShopContactMembers = async ({
  shopId,
  id,
  memberIds,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}/members/add`,
    data: {
      memberIds,
    },
  })
}

export const RemoveShopContactMembers = async ({
  shopId,
  id,
  memberIds,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCompanyDirectoryFirm/${id}/members/remove`,
    data: {
      memberIds,
    },
  })
}
