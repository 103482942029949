import { render, staticRenderFns } from "./MemberPageTabs.vue?vue&type=template&id=00f51c98&scoped=true"
import script from "./MemberPageTabs.vue?vue&type=script&lang=js"
export * from "./MemberPageTabs.vue?vue&type=script&lang=js"
import style0 from "./MemberPageTabs.vue?vue&type=style&index=0&id=00f51c98&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f51c98",
  null
  
)

export default component.exports