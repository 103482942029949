<template>
  <el-popover
    placement="bottom"
    trigger="click"
    width="264"
    :content="tag.name"
  >
    <Tag
      slot="reference"
      :type="tag.isSystem ? 'info' : 'action'"
      size="small"
      class="m-tag"
      disable-transitions
      :class="{ stag: tag.isSystem }"
      :closable="tag.isSystem ? false : true"
      @close="close(tag.id)"
    >
      {{ tag.name }}
    </Tag>
  </el-popover>
</template>

<script>
import { defineComponent } from 'vue'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'baseTag',
  components: { Tag },
  props: {
    tag: { type: Object, default: () => ({}) },
    close: { type: Function, default: () => {} },
  },
})
</script>

<style lang="scss" scoped>
.m-tag {
  @apply relative pr-[16px] max-w-[176px] truncate cursor-default;

  ::v-deep .el-tag__close {
    @apply absolute top-[4px] right-[1px] text-[#636363] font-medium;
  }
}
</style>
