<template>
  <div class="member-level-field">
    <el-form-item label="會員等級功能">
      <el-switch
        v-model="enableLevel"
        inactive-text="關閉"
        active-text="開啟"
        @change="onEnableLevel"
      />

      <div class="field-area">
        <el-form-item label="會員級別" class="flex-1" prop="level">
          <el-select
            v-model="syncFormData.level"
            :disabled="isDisabled"
            @change="onLevelChange"
          >
            <el-option
              v-for="level in levels"
              :key="level.level"
              :label="level.name"
              :value="level.level"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="flex-1" prop="levelExpiredAt">
          <p class="text-[16px] text-[#333] font-medium">
            <span class="text-danger">* </span>
            <span class="pr-2">會員到期日</span>
            <span
              v-if="syncFormData.level !== 1 && enableLevel"
              class="inline-flex items-center"
              @click="showLevelRemainDialog = true"
            >
              <img
                v-if="syncFormData.isLevelRemainFulfilled"
                src="@/assets/member/fill_check.svg"
                class="cursor-pointer"
              >
              <span
                class="text-xs cursor-pointer"
                :class="
                  syncFormData.isLevelRemainFulfilled
                    ? 'text-[#00CD20]'
                    : 'text-[#767676]'
                "
              >{{
                syncFormData.isLevelRemainFulfilled
                  ? '已符合續等資格'
                  : '未達續等資格'
              }}</span>
            </span>
          </p>
          <div class="flex flex-col">
            <el-date-picker
              v-if="!formData.allRange"
              v-model="syncFormData.levelExpiredAt"
              type="datetime"
              format="yyyy/MM/dd"
              :picker-options="pickerOption"
              :disabled="isDisabled || syncFormData.forever"
            />
            <el-checkbox
              v-model="syncFormData.forever"
              :disabled="syncFormData.level === 1 || isDisabled"
              @change="onForeverChange"
            >
              永久
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <div class="flex items-center gap-[8px]">
              <p>備註</p>
              <p class="text-sm text-gray-80">
                可於此紀錄本次修改會員級別 / 到期日原因
              </p>
            </div>
          </template>
          <el-input
            v-model="syncFormData.levelNote"
            :disabled="isDisabled"
            class="levelNote"
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 8 }"
            placeholder="請輸入"
          />
        </el-form-item>
      </div>
    </el-form-item>
    <LevelRemainDialog
      :show.sync="showLevelRemainDialog"
      :isLevelRemainFulfilled.sync="syncFormData.isLevelRemainFulfilled"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import { GetMemberLevelConfig, MemberLevelExpiredAt } from '@/api/memberLevel'
import { get } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import LevelRemainDialog from './LevelRemainDialog.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'MemberLevelField',
  components: { LevelRemainDialog },
  props: {
    formData: { type: Object, default: () => ({}) },
    member: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const enableLevel = ref(false)
    const isDisabled = computed(() => !enableLevel.value)
    const currentLevel = ref(syncFormData.value.level)
    const originExpiredDate = ref(null)

    const showLevelRemainDialog = ref(false)

    const levels = ref([])
    const levelsExpiredAt = ref([])

    const pickerOption = {
      disabledDate (time) {
        if (!originExpiredDate.value) return false
        return new Date(time.getTime()) < originExpiredDate.value
      },
    }

    const onForeverChange = (forever) => {
      if (forever) {
        syncFormData.value.levelExpiredAt = null
      }
      if (!forever) {
        if (props.member.levelExpiredAt) syncFormData.value.levelExpiredAt = props.member.levelExpiredAt
      }
    }

    const onEnableLevel = (enable) => {
      if (!enable) {
        syncFormData.value.level = null
        syncFormData.value.levelExpiredAt = null
        syncFormData.value.levelNote = null
        syncFormData.value.forever = true
      } else {
        if (!currentLevel.value) syncFormData.value.level = 1
        else syncFormData.value.level = currentLevel.value

        const levelNote = props.member.levelNote
        const levelExpiredAt = props.member.levelExpiredAt

        if (levelNote) syncFormData.value.levelNote = levelNote
        if (levelExpiredAt) {
          syncFormData.value.levelExpiredAt = levelExpiredAt
          syncFormData.value.forever = false
        }
      }
    }

    const onLevelChange = (level) => {
      if (level === 1) {
        syncFormData.value.forever = true
        syncFormData.value.levelExpiredAt = null
      }
    }

    onMounted(async () => {
      originExpiredDate.value = new Date(props.member.levelExpiredAt)
      simpleFetch(GetMemberLevelConfig, { shopId: shopId.value }, (res) => {
        levels.value = res
      })
      simpleFetch(MemberLevelExpiredAt, { shopId: shopId.value }, (res) => {
        levelsExpiredAt.value = res
      })
      if (props.formData.level) {
        enableLevel.value = true
      }
      if (!props.formData.level) {
        enableLevel.value = false
        syncFormData.value.level = null
      }
    })

    return {
      syncFormData,
      isDisabled,
      levels,
      levelsExpiredAt,
      onForeverChange,
      enableLevel,
      onEnableLevel,
      showLevelRemainDialog,
      onLevelChange,
      pickerOption,
    }
  },
})
</script>

<style lang="postcss" scoped>
.field-area {
  @apply p-[20px] bg-[#F5F7FA];
}

::v-deep .comment.el-textarea {
  @apply w-full;
}
::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
</style>
