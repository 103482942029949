<template>
  <el-drawer
    title="預約資訊"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="reservation-order-detail"
    @close="$emit('close')"
  >
    <!-- 操作按鈕 -->
    <div class="operation">
      <el-button
        type="primary"
        @click="updateReservationTime('departure')"
        :disabled="getData(reservation, 'AppointmentReservationAipd.departureAt') || orderStatus === 'cancel'"
      >
        出發
      </el-button>
      <el-button
        type="primary"
        @click="updateReservationTime('getInto')"
        :disabled="getData(reservation, 'AppointmentReservationAipd.getIntoAt')  || orderStatus === 'cancel'"
      >
        上車
      </el-button>
      <el-button
        type="primary"
        @click="updateReservationTime('getOff')"
        :disabled="getData(reservation, 'AppointmentReservationAipd.getOffAt') || orderStatus === 'cancel'"
      >
        下車
      </el-button>
    </div>
    <div style="padding: 20px">
      <!-- 預約訂單 -->
      <div>
        <p class="section-block-title">{{ blockIndex.orderInfo }}. 預約訂單</p>
        <OrderInfoBlock :order="reservation" @refresh="refreshOrder" />
        <div class="flex justify-end">
          <el-button plain @click="showHistory = true">
            訂單紀錄
          </el-button>
          <el-button
            v-if="getData(selecReservation, 'AppointmentOrder.status')"
            plain
            :disabled="orderStatus === 'cancel'"
            @click="onCancelOrder(reservation)"
          >
            取消預約
          </el-button>
        </div>
      </div>

      <!-- 詳細資訊 -->
      <hr style="margin: 12px 0">
      <div>
        <p class="section-block-title">{{ blockIndex.orderDetail }}. 詳細資訊</p>
        <OrderDetailBlock
          :order="reservation"
          :syncData="syncData"
          @refresh="refreshOrder"
        />
      </div>

      <div v-if="showPayment">
        <hr style="margin: 12px 0">
        <p class="section-block-title">{{ blockIndex.paymentInfo }}. 付款資訊</p>

        <div class="flex flex-col" style="gap: 20px">
          <!-- 付款資訊 -->
          <OrderPaymentBlock
            v-if="getData(reservation, 'AppointmentOrder.AppointmentOrderDeposit')"
            :reservation="reservation"
            @refresh="refreshOrder"
          />

          <!-- 堂票預約資訊 -->
          <ClassTicketDetailBlock
            v-if="useReservationClassTicket"
            :orderStatus="orderStatus"
            :orderId="getData(selecReservation, 'AppointmentOrderId')"
            :reservationId="getData(selecReservation, 'id')"
            :classTickets.sync="syncData.classTickets"
            :service="syncData.serviceDetail"
            @refresh="refreshOrder"
          />
        </div>
      </div>
    </div>

    <div class="dialog-footer flex justify-end" style="padding: 20px">
      <el-button plain @click="$emit('close')">返回</el-button>
    </div>

    <ReservationOrderHistory
      :show.sync="showHistory"
      :orderId="getData(reservation, 'AppointmentOrder.id')"
      @close="showHistory = false"
    />
  </el-drawer>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import { statusTranslate } from '@/utils/helper'
import { FindReservation } from '@/api/reservation'
import { FindService } from '@/api/service'
import OrderInfoBlock from './components/OrderInfoBlock.vue'
import OrderDetailBlock from './components/OrderDetailBlock.vue'
import OrderPaymentBlock from './components/OrderPaymentBlock.vue'
import ClassTicketDetailBlock from './components/ClassTicketDetailBlock.vue'
import { getData } from '@/utils/object'
import ReservationOrderHistory from '@/components/Reservation/ReservationOrderHistory/ReservationOrderHistory.vue'
import dayjs from 'dayjs'
import { usePermissions } from '@/use/permissions'
import { useFetch } from '@/use/fetch'
import { get } from 'lodash'

const featureKeys = {
  useClassTicket: 'admin.appointmentReservationTicket.find',
}

export default defineComponent({
  name: 'ReservationOrderDetail',
  components: {
    OrderInfoBlock,
    OrderDetailBlock,
    OrderPaymentBlock,
    ReservationOrderHistory,
    ClassTicketDetailBlock,
  },
  props: {
    show: Boolean,
    width: String,
    selecReservation: Object,
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const { simpleFetch } = useFetch()
    const syncData = reactive({
      classTickets: [],
      serviceDetail: {},
    })

    const reservation = ref({})

    const useReservationClassTicket = computed(() => checkAction(featureKeys.useClassTicket))
    const orderStatus = computed(() => get(reservation.value, 'status'))
    const showPayment = computed(() => get(props.selecReservation, 'AppointmentOrder.AppointmentOrderDeposit') || get(props.selecReservation, 'isUseClassTicket'))
    return { useReservationClassTicket, syncData, simpleFetch, orderStatus, reservation, showPayment }
  },
  data: () => ({
    showHistory: false,
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
    page () {
      return this.$route.name
    },
    attachServices () {
      if (!this.reservation.AppointmentServiceAttaches) return [{ name: '無', id: 0 }]
      return this.reservation.AppointmentServiceAttaches
    },
    blockIndex () {
      const index = {
        orderInfo: 1,
        orderDetail: 2,
        paymentInfo: 3,
      }
      if (!getData(this.reservation, 'AppointmentOrder.AppointmentOrderDeposit')) {
        index.orderDetail = 2
      }
      return index
    },
    syncShow: {
      get () {
        return this.show
      },

      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
  watch: {
    async show () {
      if (this.show) {
        await this.findReservation()

        this.simpleFetch(FindService, { shopId: this.shopId, id: get(this.reservation, 'AppointmentServiceId') }, (res) => {
          console.log('findService', res)
          this.syncData.serviceDetail = res
        })
      }
    },
  },

  methods: {
    getData,
    statusContent: (status) => statusTranslate(status),

    dateFormat (date) {
      if (!date) return '無'
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },

    // 389d7351-8cd5-4656-bfc6-8b6a3285b1d7
    async findReservation () {
      const res = await FindReservation({
        shopId: this.shopId,
        id: this.selecReservation.id,
      })

      this.reservation = res
    },

    async onCancelOrder (reservation) {
      this.$emit('cancel', reservation)
      // this.refreshOrder()
    },

    async refreshOrder () {
      await this.findReservation()
      this.simpleFetch(FindService, { shopId: this.shopId, id: get(this.reservation, 'AppointmentServiceId') }, (res) => {
        console.log('findService', res)
        this.syncData.serviceDetail = res
      })

      this.$emit('refresh')
    },

    updateReservationTime(type) {
      this.$emit('updateReservation', {
        type,
      })
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
  @apply mb-0;
}

.section-block-title {
  @apply text-primary-100 font-medium text-[18px] pb-[12px];
}

.operation {
  padding: 0 20px;
  align-content: center;
  @apply flex gap-4;
  button {
    @apply w-[100px] h-[40px];
  }
}

</style>
