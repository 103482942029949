export const genders = {
  male: 'male',
  female: 'female',
}

export const genderName = {
  [genders.male]: '男性',
  [genders.female]: '女性',
}

export const genderOptions = Object.keys(genderName).map(key => ({
  name: genderName[key],
  value: key,
}))
