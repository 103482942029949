export const classTicketUseRecordTypeConfig = {
  ADMIN_USE: { label: '後台使用', value: 'ADMIN_USE' },
  ADMIN_CANCEL: { label: '後台取消使用', value: 'ADMIN_CANCEL' },
  SALES_USE: { label: '銷售模組使用', value: 'SALES_USE' },
  SALES_CANCEL: { label: '銷售模組作廢（取消使用）', value: 'SALES_CANCEL' },
  CLIENT_USE: { label: '透過顧客 client 端使用', value: 'CLIENT_USE' },
  GIVE_USE: { label: '轉贈扣除', value: 'GIVE_USE' },
  APPOINTMENT_BOOKED: { label: '預約預扣', value: 'APPOINTMENT_BOOKED' },
  APPOINTMENT_UNBOOKED: { label: '預約預扣取消', value: 'APPOINTMENT_UNBOOKED' },
  APPOINTMENT_CHECKED: { label: '預約核銷', value: 'APPOINTMENT_CHECKED' },
}

export const classTicketUseRecordOriginConfig = {
  client: { label: '前台使用', value: 'client' },
  admin: { label: '後台使用', value: 'admin' },
  sales: { label: '銷售模組使用', value: 'sales' },
  give: { label: '轉贈扣除', value: 'give' },
  eventPlaybook: { label: '系統行銷活動發放', value: 'eventPlaybook' },
  appointment: { label: '預約模組', value: 'appointment' },
}

export const servicePromiseStatusConfig = {
  noPromise: { label: '-', value: 'noPromise' },
  waiting: { label: '待價保', value: 'waiting' },
  promised: { label: '未核銷', value: 'promised' },
  used: { label: '已核銷', value: 'used' },
  expired: { label: '已到期核銷', value: 'expired' },
  cancelled: { label: '系統取消', value: 'cancelled' },
}
