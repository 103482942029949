<template>
  <el-dialog
    class="dialog"
    title="匯出"
    v-bind="$attrs"
    width="450px"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form :model="formData" label-position="top">
      <el-form-item v-if="!allRange" label="匯出範圍">
        <el-radio-group v-model="formData.allRange">
          <el-radio :label="true">全部範圍</el-radio>
          <el-radio :label="false">指定範圍</el-radio>
        </el-radio-group>

        <div>
          <el-date-picker
            v-if="!formData.allRange"
            v-model="formData.range"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
          />
        </div>
      </el-form-item>
      <el-form-item label="匯出格式">
        <el-select
          v-model="formData.format"
          style="width: 100%"
          placeholder="請選擇格式"
        >
          <el-option
            v-for="item in exportFormatOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        type="primary"
        style="margin-left: 12px"
        @click="$emit('export', {
          all: formData.allRange || allRange,
          range: formData.range,
        })"
      >
        確認
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  exportFormatOptions,
} from '@/config/export'
export default {
  name: 'ExportDialog',
  props: {
    allRange: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    exportFormatOptions,
    formData: {
      allRange: true,
      range: null,
      format: 'excel',
    },
  }),
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: left;
}

::v-deep .el-input {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-select {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
