<template>
  <div class="member-level-record-item">
    <p class="dateTime">{{ displayData.dateTime }}</p>
    <div v-if="displayData.user" class="row">
      <p class="label">操作人員</p>
      <p>{{ displayData.user }}</p>
    </div>
    <div class="row">
      <p class="label">{{ displayData.source }}</p>
      <p>{{ record.originLevel || '無' }} 等級 → {{ record.level || '無' }} 等級；等級期限為 {{ displayData.expiredDate }}</p>
    </div>
    <div class="row">
      <p class="label">符合續等</p>
      <p>{{ record.isFulfilled ? '是' : '否' }}</p>
    </div>
    <div class="row">
      <p class="label">備註</p>
      <p>{{ record.note ? record.note : '-' }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'MemberLevelRecordItem',
  props: {
    record: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayData = computed(() => {
      const data = props.record
      const sourceType = get(data, 'source')

      let expiredDate
      const expiredAt = get(data, 'expiredAt')
      if (!expiredAt) expiredDate = '永久'
      else expiredDate = formatDate(expiredAt)

      return {
        user: get(data, 'User.name'),
        dateTime: formatDate(get(data, 'updatedAt'), 'YYYY/MM/DD HH:mm'),
        expiredDate,
        source: get(sourceConfig, `${sourceType}.label`),
      }
    })

    const sourceConfig = {
      manual: { label: '手動更改', value: 'manual' },
      init: { label: '初始等級', value: 'init' },
      systemUpgrade: { label: '系統升等', value: 'systemUpgrade' },
      systemDowngrade: { label: '系統降等', value: 'systemDowngrade' },
      systemRemain: { label: '系統續等', value: 'systemRemain' },
      configChange: { label: '設定異動', value: 'configChange' },
    }

    return { sourceConfig, get, formatDate, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.label {
  @apply font-bold w-[56px];
}

.row {
  @apply flex gap-[12px];
}
</style>
