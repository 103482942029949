<template>
  <el-select
    v-model="syncModel"
    filterable
    remote
    :multiple="multiple"
    clearable
    reserve-keyword
    placeholder="請選擇使用券"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getCoupon"
  >
    <el-option
      v-for="(item, index) in couponList"
      :key="`${index} - ${item.name}`"
      :label="item.name"
      :value="getValue(item)"
    />
  </el-select>
</template>
<script>
import { GetCoupon } from '@/api/lottery/coupon'
import { mapGetters } from 'vuex'
export default {
  name: 'CouponSearch',
  props: ['model', 'type', 'objKey', 'multiple'],

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    loading: false,
    couponList: [],
  }),

  async mounted () {
    await this.getCoupon()
  },

  methods: {
    getValue (item) {
      if (this.objKey) return item[this.objKey]
      else return item
    },
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.membersList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getCoupon(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getCoupon (query = null) {
      const [res, err] = await GetCoupon({
        shopId: this.shop,
        name: query || undefined,
        limit: 50,
      })
      if (err) return this.$logger.danger(err)
      this.couponList = res
      console.log(res)
    },
  },
}
</script>

<style scoped lang="scss"></style>
