<template>
  <el-select
    v-model="syncModel"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="placeholder || '請輸入搜尋堂票'"
    no-match-text="目前無可綁定的堂票"
    no-data-text="目前無可綁定的堂票"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    v-bind="$attrs"
    @change="$emit('change', find(dataList, {id: $event}))"
    @clear="getData"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <el-option
      v-for="(item, index) in displayData"
      :key="item.id"
      :label="displayLabel(item, index)"
      :value="getKeyValue(item)"
    />
  </el-select>
</template>
<script>
import { GetClassTicket } from '@/api/classTicket'
import { mapGetters } from 'vuex'
import { find } from 'lodash'

export default {
  name: 'ClassTicketSearch',
  props: ['model', 'objKey', 'customLabel', 'customFilter', 'placeholder'],

  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },

    displayData () {
      if (this.customFilter) return this.customFilter(this.dataList)
      return this.dataList
    },

  },
  // :value="{name:item.UserInfo.name, phone:item.UserInfo.phone}"

  data: () => ({
    loading: false,
    dataList: [],
  }),

  async mounted () {
    await this.getData()
  },

  methods: {
    find,
    getKeyValue (item) {
      if (!this.objKey) return item
      return item[this.objKey]
    },
    displayLabel (item, index) {
      if (this.customLabel) return this.customLabel(item, index)
      return item.name
    },
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.dataList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getData(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getData (query = null) {
      const [res, err] = await GetClassTicket({
        shopId: this.shop,
        name: (query) || undefined,
        limit: 50,
      })
      this.dataList = res
      if (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
