<template>
  <div class="history-record">
    <div class="content-wrapper">
      <el-steps
        v-if="historyData.length >= 1"
        direction="vertical"
        :active="historyData.length"
      >
        <el-step
          v-for="log in historyData"
          :key="log.id"
          :description="log.time"
        >
          <template slot="description">
            <div style="padding-bottom: 70px">
              <p class="dateTime">{{ formatDate(log.updatedAt) }}</p>
              <slot :record="log" />
            </div>
          </template>
        </el-step>
      </el-steps>

      <EmptyBlock v-else />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'HistoryRecord',
  props: {
    historyData: { type: Array, default: () => [] },
  },
  setup (props) {
    return { formatDate }
  },
})
</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[20px];
}
.dateTime {
  @apply text-gray-60 text-sub pb-[8px];
}

::v-deep .el-step__description {
  @apply pr-0 top-[-4px] relative;
}

::v-deep .el-step__icon.is-text {
  @apply top-[-4px] h-[15px] w-[15px] ml-[5px] bg-primary-100;
}

::v-deep .el-step__icon-inner {
  @apply hidden;
}
</style>
