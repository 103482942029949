export const RewardTypesShowConfig = {
  coupon: { name: '票券 - 使用券', value: 'coupon' },
  exchange: { name: '票券 - 兌換券', value: 'exchange' },
  coin: { name: '儲值金', value: 'coin' },
  cashback: { name: '回饋金', value: 'cashback' },
  point: { name: '點數', value: 'point' },
  miss: { name: '再接再厲', value: 'miss' },
}
export const rewardTypesCreateConfig = {
  coupon: { name: '票券', value: 'coupon' },
  cashback: { name: '回饋金', value: 'cashback' },
  point: { name: '點數', value: 'point' },
  // coin: { name: '儲值金', value: 'coin' },
  // miss: { name: '再接再厲', value: 'miss' },
}

export const lotteryTypesConfig = {
  'scratch-off': { name: '刮刮樂', value: 'scratch-off' },
  'luck-spin-wheel': { name: '幸運轉盤', value: 'luck-spin-wheel' },
}

export const lotteryReqConfig = {
  memberCard: {
    type: 'memberCard',
    name: '會員卡等級篩選',
    value: 'memberCard',
  },
  totalUsed: {
    type: 'totalUsed',
    name: '單一會員總次數限制',
    value: 'totalUsed',
  },
  dayUsed: {
    type: 'dayUsed',
    name: '單一會員每日抽獎次數限制',
    value: 'dayUsed',
  },
  memberTag: { type: 'memberTag', name: '會員標籤', value: 'memberTag' },
}
